import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Axios from '../../utils/Axios';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import TableHeader from '../../components/TableHeader/TableHeader';
import Layout from '../../components/Layout/Layout';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import * as actions from '../../store/actions';
import { Line } from 'react-chartjs-2';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  StepButton,
  Button,
  TextField,
  Slider,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Available, NotAvailable, Camera, ArrowDown, ArrowUp } from '../../utils/Icons';
import classes from './EditSession.module.css';
import { currentApiUrlPrefix } from '../../utils/EnvironmentVariable';

class EditSession extends Component {
  state = {
    session: null,
    activeStep: 0,
    programs: [],
    result: false,
    juciness: false,
    error: null,
    tempSelect: true,
    is_temp: false,
    missingFedbackSessions: null,
    showSelect: false,
    measurements: null,
    loaded: false,
    juicinessLoaded: false,
    isJucinessReq: false,
  };

  juicinessProductIds = [
    'salmon fillet',
    'sea bass fillet',
    'tuna steak',
    'roast beef',
    'veal roast',
    'pork roast',
    'whole fish (sea bream type)',
    'roasted chicken',
  ];
  juicinessProductIdx = [1, 2, 3, 4, 5, 6, 27, 28];
  probProductIds = ['salmon fillet', 'sea bass fillet', 'tuna steak', 'roast beef', 'veal roast', 'pork roast'];
  probProductIdx = [1, 2, 3, 4, 5, 6];

  // isJucinessReq = this.state.session ? (this.juicinessProductIds.includes(this.state.session.program) || this.juicinessProductIdx.includes(this.state.session.program)) : false;

  // static getDerivedStateFromProps(props, state) {
  //     let programs = [];
  //     if (props.programs.programs) {
  //         programs = Object.keys(props.programs.programs).map(id => ({ id: id, name: props.programs.programs[id] }));
  //     }
  //     return {
  //         ...state,
  //         programs: programs,
  //     }
  // }

  fetchMissingFedbackSessions = (appliance_id) => {
    Axios.get(`bakingsessions?appliance=${appliance_id}&missing_complete_feedback`).then((res) => {
      this.setState({
        missingFedbackSessions: res.data.results,
      });
    });
  };

  fetchMeasurements = () => {
    Axios.get(`bakingsessions/${this.props.match.params.id}/measurements`).then((res) => {
      const data = res.data.sort(function (a, b) {
        return a.timestamp - b.timestamp;
      });
      // const data = res.data;
      this.setState({ measurements: data });
    });
  };

  componentDidMount() {
    //this.props.fetchBakingSession(this.props.match.params.id);
    Axios.get(`bakingsessions/` + this.props.match.params.id)
      .then((res) => {
        //this.setState({ session: res.data });
        if (res.data.details) {
          const is_temp = res.data.is_temp;
          this.setState({
            session: res.data,
            mainSession: res.data,
            is_temp: res.data.is_temp,
          });
          if (is_temp) {
            this.fetchMissingFedbackSessions(res.data.appliance_id);
          }
        } else {
          Axios.get(`create-feedback/` + this.props.match.params.id)
            .then((res) => {
              if (res.status === 200) {
                Axios.get(`bakingsessions/` + this.props.match.params.id)
                  .then((resData) => {
                    const is_temp = resData.data.is_temp;
                    this.setState({
                      session: resData.data,
                      mainSession: resData.data,
                      is_temp: resData.data.is_temp,
                    });
                    if (is_temp) {
                      this.fetchMissingFedbackSessions(resData.data.appliance_id);
                    }
                  })
                  .catch((err) => {
                    this.setState({ error: err });
                  });
              }
            })
            .catch((err) => {
              this.setState({ error: err });
            });
        }
      })
      .catch((err) => {
        this.setState({ error: err });
      });

    this.fetchMeasurements();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeStep !== prevState.activeStep) {
      this.setState({ session: this.state.mainSession });
    }
    if (this.state.mainSession !== prevState.mainSession && this.state.mainSession.program && this.state.mainSession.program !== -1) {
      if (Number.isInteger(this.state.mainSession.program)) {
        if (this.juicinessProductIdx.includes(this.state.mainSession.program)) {
          this.setState({ isJucinessReq: true });
        } else {
          this.setState({ isJucinessReq: false });
        }
      } else {
        if (this.juicinessProductIds.includes(this.state.mainSession.program.toLowerCase())) {
          this.setState({ isJucinessReq: true });
        } else {
          this.setState({ isJucinessReq: false });
        }
      }
    }
  }

  putEditedSession = () => {
    return Axios({
      method: 'put',
      url: `bakingsessions/` + this.props.match.params.id,
      data: this.state.session,
    });
  };

  jumpStepHandler = (index) => {
    this.setState({ activeStep: index });
  };

  nextClickHandler = (method = 'next') => {
    const forwordBy = this.state.activeStep === 1 && !this.state.isJucinessReq ? 2 : 1;
    if (method !== 'skip') {
      this.putEditedSession()
        .then((res) => {
          if (res.status === 200) {
            this.props.changeFetchingFlag();
            this.setState((prevState) => ({
              activeStep: prevState.activeStep < 3 ? prevState.activeStep + forwordBy : prevState.activeStep,
              mainSession: prevState.session,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep < 3 ? prevState.activeStep + forwordBy : prevState.activeStep,
        session: prevState.mainSession,
      }));
    }
  };

  exitClickHandler = () => {
    this.putEditedSession()
      //this.props.history.replace('/');
      .then((res) => {
        this.props.changeFetchingFlag();
        if (res.status === 200) {
          this.props.history.replace('/');
        }
      });
  };

  cancelClickHandler = () => {
    this.props.history.replace('/');
  };

  fetchImage = (type) => {
    this.setState({
      [`${type}BoothErr`]: false,
      [`${type}Loding`]: true,
    });
    Axios.get(`bakingsessions/${this.props.match.params.id}/image?type=${type}`)
      .then((res) => {
        if (res.data.result) {
          let updatedImages = null;
          if (this.state.session.details && this.state.session.details.images) {
            updatedImages = {
              ...this.state.session.details.images,
              [type]: res.data[type],
            };
          } else {
            updatedImages = {
              [type]: res.data.result,
            };
          }
          let updatedImagesMain = null;
          if (this.state.mainSession.details && this.state.mainSession.details.images) {
            updatedImagesMain = {
              ...this.state.mainSession.details.images,
              [type]: res.data[type],
            };
          } else {
            updatedImagesMain = {
              [type]: res.data.result,
            };
          }
          this.setState({
            session: {
              ...this.state.session,
              details: {
                ...this.state.session.details,
                images: updatedImages,
              },
            },
            mainSession: {
              ...this.state.mainSession,
              details: {
                ...this.state.mainSession.details,
                images: updatedImagesMain,
              },
            },
            [type]: true,
            [`${type}Loding`]: false,
          });
          // this.state.session.details.images.result
        }else{
          this.setState({
            [`${type}Loding`]: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          [`${type}BoothErr`]: true,
          [`${type}Loding`]: false,
        });
      });
  };

  addResultIMGHandler = () => {
    this.fetchImage('result');
  };

  addJuicinessIMGHandler = () => {
    this.fetchImage('juciness');
  };

  programChangeHandler = (e, val) => {
    const updatedProgram = { ...this.state.session };
    if (val) {
      updatedProgram.program = val.index;
    } else {
      updatedProgram.program = -1;
    }
    //console.log(updatedProgram);
    this.setState({ session: updatedProgram });
  };

  feedbackChnageHandler = (val, field) => {
    const updatedFeedback = { ...this.state.session };
    updatedFeedback.details = {
      ...this.state.session.details,
      [field]: val,
    };
    this.setState({ session: updatedFeedback });
  };

  wtChangeHandler = (val, field) => {
    //if (val) {
    val = Math.round(val);
    const updatedWeight = { ...this.state.session };
    updatedWeight.details = {
      ...this.state.session.details,
      weight: {
        ...this.state.session.details.weight,
        [field]: val,
      },
    };
    this.setState({ session: updatedWeight });
    //}
  };

  disregardChnageHandler = (val, field) => {
    this.setState({
      session: {
        ...this.state.session,
        [field]: val,
      },
    });
  };

  msToHMS = (ms) => {
    var seconds = ms / 1000;
    var hours = ('0' + parseInt(seconds / 3600)).slice(-2);
    seconds = seconds % 3600;
    var minutes = ('0' + parseInt(seconds / 60)).slice(-2);
    seconds = ('0' + parseInt(seconds % 60)).slice(-2);
    // return hours + ":" + minutes + ":" + seconds;
    return hours + ':' + minutes + ':' + seconds;
  };

  getStepContent = () => {
    // console.log(JSON.stringify(this.state.measurements))
    const camera = <img className={classes.ImgSvg} src={Camera} alt="Camera" />;

    let srcPre = currentApiUrlPrefix.split(/[/?#]/);
    srcPre = srcPre[0] + '//' + srcPre[2];
    const curvData = {
      time: [],
      curTemp: [],
      trgTemp: [],
      probeCurrentTemp: [],
      probeTargetTemp: [],
    };
    let timeProgress = 0;
    this.state.measurements &&
      this.state.measurements.forEach((measurement, idx) => {
        curvData.time = [...curvData.time, measurement.timestamp];
        curvData.curTemp = [...curvData.curTemp, measurement.currentTemp];
        curvData.trgTemp = [...curvData.trgTemp, measurement.targetTemp];
        curvData.probeCurrentTemp = [...curvData.probeCurrentTemp, measurement.probeCurrentTemp];
        curvData.probeTargetTemp = [...curvData.probeTargetTemp, measurement.probeTargetTemp];
      });
    // curvData.time[0] = curvData.time[1];
    // console.log( curvData.time);
    const dataSetConst = {
      fill: false,
      lineTension: 0.1,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
    };

    const options = {
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              parser: 'HH:mm:ss',
              // round: 'day'
              //   stepSize: 0.053,
              maxTicksLimit: 10,
              maxRotation: 65,
              minRotation: 65,
              tooltipFormat: 'll HH:mm',
            },
            ticks: {
              maxTicksLimit: 20,
              callback: (value, index, values) => {
                let timeCalculation = null;
                if (index === 0) {
                  timeCalculation = 0;
                  timeProgress = 0;
                } else {
                  timeCalculation = values[index].value - values[index - 1].value;
                }
                timeProgress += timeCalculation;
                return this.msToHMS(timeProgress);
              },
            },
            scaleLabel: {
              display: true,
              labelString: 'Duration (hh:mm:ss)',
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Temperature (celsius)',
            },
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 250,
            },
          },
        ],
      },
    };
    const data = {
      labels: curvData.time,
      datasets: [
        {
          ...dataSetConst,
          label: 'Current Temperature',
          data: curvData.curTemp,
          // backgroundColor: 'rgba(75,192,192,1)',
          borderColor: 'rgba(75,192,192,1)',
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: 'rgba(75,192,192,1)',
        },
        {
          ...dataSetConst,
          label: 'Target Temperature',
          data: curvData.trgTemp,
          // backgroundColor: 'rgba(99,191,102,1)',
          borderColor: 'rgba(99,191,102,1)',
          pointHoverBackgroundColor: 'rgba(99,191,102,1)',
          pointBackgroundColor: 'rgba(99,191,102,1)',
        },
      ],
    };

    const probData = {
      labels: curvData.time,
      datasets: [
        {
          ...dataSetConst,
          label: 'Current Temperature',
          data: curvData.probeCurrentTemp,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
        },
        {
          ...dataSetConst,
          label: 'Target Temperature',
          data: curvData.probeTargetTemp,
          backgroundColor: 'rgba(99,191,102,0.4)',
          borderColor: 'rgba(99,191,102,1)',
        },
      ],
    };

    let probCurv = null;
    if (this.state.session) {
      if (Number.isInteger(+this.state.session.program)) {
        probCurv = this.probProductIdx.includes(this.state.session.program);
      } else {
        probCurv = this.probProductIds.includes(this.state.session.program.toLowerCase());
      }
    }

    switch (this.state.activeStep) {
      case 0:
        return (
          <div className={classes.DetailsContent}>
            <div className={classes.DetailsGrid}>
              <div className={classes.ImageDiv}>
                {this.state.measurements ? (
                  <ImageSlider slideImages={this.state.measurements} bId={this.props.match.params.id} />
                ) : (
                    <span className={classes.CenterText}>Image/Video</span>
                  )}
              </div>
              <div className={classes.OtherDiv}>
                <span className={`${classes.CenterText} ${classes.ChartContainer}`}>
                  <span className={classes.CurveTitle}>Oven Temperature Curve</span>
                  <Line className={classes.Canvas} data={data} options={options} />
                </span>
              </div>
            </div>
            {probCurv && (
              <div className={classes.ProbDiv}>
                <span className={classes.CurveTitle}>Prob Temperature Curve</span>
                <Line className={classes.Canvas} data={probData} options={options} />
              </div>
            )}
            {/* <div className={classes.TempCurve}><span className={classes.CenterText}>Temperature Curve</span></div> */}
          </div>
        );
      case 1:
        return (
          <div className={classes.StepContent}>
            {this.state.result ||
              (this.state.session &&
                this.state.session.details &&
                this.state.session.details.images &&
                this.state.session.details.images.result) ? (
                <div style={{ height: '100%', textAlign: 'center' }}>
                  {!this.state.resultLoaded && <div className={classes.IMGLoder}>Loading...</div>}
                  <img
                    onLoad={() => this.setState({ resultLoaded: true })}
                    alt="resultImage"
                    src={srcPre + this.state.session.details.images.result}
                    className={classes.ResultImage}
                  />
                  <Button className={`${classes.AddImgBtn} ${classes.TakeNew}`} onClick={this.addResultIMGHandler}>
                    {camera} Take New Image
                </Button>
                </div>
              ) : (
                <div className={`${classes.ButtonWrapper} ${classes.AddImg}`}>
                  {this.state.resultLoding && <CircularProgress size={60} thickness={5} />}
                  {!this.state.resultLoding && (
                    <Button className={classes.AddImgBtn} onClick={this.addResultIMGHandler}>
                      {camera} Add Result Image
                    </Button>
                  )}
                  {this.state.resultBoothErr && <div className={classes.PhotoBoothErr}>Turn on photo booth and check connection</div>}
                </div>
              )}
          </div>
        );
      case 2:
        return (
          <div className={classes.StepContent}>
            {this.state.juciness ||
              (this.state.session &&
                this.state.session.details &&
                this.state.session.details.images &&
                this.state.session.details.images.juciness) ? (
                <div style={{ height: '100%', textAlign: 'center' }}>
                  {!this.state.juicinessLoaded && <div className={classes.IMGLoder}>Loading...</div>}
                  <img
                    onLoad={() => this.setState({ juicinessLoaded: true })}
                    alt="juicinessImage"
                    src={srcPre + this.state.session.details.images.juciness}
                    className={classes.ResultImage}
                  />
                  <Button className={`${classes.AddImgBtn} ${classes.TakeNew}`} onClick={this.addJuicinessIMGHandler}>
                    {camera} Take New Image
                </Button>
                </div>
              ) : (
                <div className={`${classes.ButtonWrapper} ${classes.AddImg}`}>
                  {this.state.jucinessLoding && <CircularProgress size={60} thickness={5} />}
                  {!this.state.jucinessLoding && (
                    <Button className={classes.AddImgBtn} onClick={this.addJuicinessIMGHandler}>
                      {camera} Add Juiciness Image
                    </Button>
                  )}
                  {this.state.jucinessBoothErr && <div className={classes.PhotoBoothErr}>Turn on photo booth and check connection</div>}
                </div>
              )}
          </div>
        );
      case 3:
        return (
          <div className={classes.FeedbackContainer}>
            <Grid container className={classes.FeedbackRow}>
              <Grid item className={classes.FeedbackTitles}>
                Weight
              </Grid>
              <Grid item className={classes.FeedbackInputs}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Grid item>
                    Before Cooking (g) :
                    <TextField
                      id={'wt-before-helper'}
                      type="number"
                      //defaultValue={this.state.fields[field].value}
                      //className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        this.state.session.details && this.state.session.details.weight.before > 0
                          ? this.state.session.details.weight.before
                          : ''
                      }
                      className={classes.WTInput}
                      onChange={(e) => this.wtChangeHandler(+e.target.value, 'before')}
                    />
                  </Grid>
                  <Grid item>
                    After Cooking (g) :
                    <TextField
                      id={'wt-after-helper'}
                      type="number"
                      //defaultValue={this.state.fields[field].value}
                      //className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        this.state.session.details && this.state.session.details.weight.after > 0
                          ? this.state.session.details.weight.after
                          : ''
                      }
                      className={classes.WTInput}
                      onChange={(e) => this.wtChangeHandler(+e.target.value, 'after')}
                    />
                  </Grid>
                  <Grid item>
                    Cookware (g) :
                    <TextField
                      id={'cookware-helper'}
                      type="number"
                      //defaultValue={this.state.fields[field].value}
                      //className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        this.state.session.details && this.state.session.details.weight.cookware > 0
                          ? this.state.session.details.weight.cookware
                          : ''
                      }
                      className={classes.WTInput}
                      onChange={(e) => this.wtChangeHandler(+e.target.value, 'cookware')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={classes.FeedbackRow}>
              <Grid item className={classes.FeedbackTitles}>
                Texture
              </Grid>
              <Grid item className={classes.FeedbackInputs}>
                <Slider
                  defaultValue={0}
                  aria-labelledby="texture"
                  marks
                  valueLabelDisplay="auto"
                  min={-5}
                  max={5}
                  step={1}
                  value={
                    this.state.session.details && this.state.session.details.texture >= -5 && this.state.session.details.texture <= 5
                      ? this.state.session.details.texture
                      : null
                  }
                  classes={{
                    mark: classes.TextureMark,
                    rail: classes.TextureRail,
                    track: classes.TextureTrack,
                    thumb: classes.TextureThumb,
                  }}
                  onChange={(e, val) => this.feedbackChnageHandler(+val, 'texture')}
                />
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Grid item>
                    <div style={{ textAlign: 'left' }}>-5</div>
                    <div>Undercooked</div>
                  </Grid>
                  <Grid item>
                    <div>0</div>
                    <div>Perfect</div>
                  </Grid>
                  <Grid item>
                    <div style={{ textAlign: 'right' }}>5</div>
                    <div>Overcooked</div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={classes.FeedbackRow}>
              <Grid item className={classes.FeedbackTitles}>
                Additional Comments
              </Grid>
              <Grid item className={classes.FeedbackInputs}>
                <TextField
                  id={'wt-before-helper'}
                  type="text"
                  //defaultValue={this.state.fields[field].value}
                  //className={classes.textField}
                  multiline={true}
                  rows={7}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.session.details ? this.state.session.details.comment : ''}
                  className={classes.CommentInput}
                  onChange={(e) => this.feedbackChnageHandler(e.target.value, 'comment')}
                />
              </Grid>
            </Grid>
          </div>
        );
      default:
        return true;
    }
  };

  sessionClickHandler = (session) => {
    Axios.post(`bakingsessions/${session._id}/assign/${this.state.session._id}`).then((res) => {
      this.props.history.push('/edit-session/' + session._id);
      this.setState({
        session: res.data,
        is_temp: false,
        missingFedbackSessions: null,
        showSelect: false,
      });
    });
  };

  sessionsList = () => {
    return (
      <Table aria-label="customized table" className={classes.SelectTabel}>
        <TableBody className={`${classes.SelectTabelBody} ${this.state.showSelect && classes.ShowSelect}`}>
          {this.state.missingFedbackSessions &&
            this.state.missingFedbackSessions.map((session) => (
              <TableRow onClick={() => this.sessionClickHandler(session)} key={Math.random(1000)} className={classes.SelectRow}>
                <TableCell align="center">{session.appliance_name}</TableCell>
                <TableCell align="center">{session._id}</TableCell>
                <TableCell align="center">
                  <Moment format="D.MM.YYYY" withTitle>
                    {new Date(session.timestamp * 1000)}
                  </Moment>
                </TableCell>
                <TableCell align="center">
                  <Moment format="HH:mm" withTitle>
                    {new Date(session.timestamp * 1000)}
                  </Moment>
                </TableCell>
                <TableCell align="center">{this.secondsToHMS(session.duration.overall - session.duration.preheating)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  };

  showSelect = () => {
    this.setState((prevState) => {
      return { showSelect: !prevState.showSelect };
    });
  };

  secondsToHMS = (totalSeconds) => {
    //let totalSeconds = 28565;
    let hours = ('0' + Math.floor(totalSeconds / 3600)).slice(-2);
    totalSeconds %= 3600;
    let minutes = ('0' + Math.floor(totalSeconds / 60)).slice(-2);
    let seconds = ('0' + (totalSeconds % 60)).slice(-2);
    return `${hours}:${minutes}:${seconds}`;
  };

  checkStepStaus = (label) => {
    if (this.state.session && this.state.session.details) {
      switch (label) {
        case 'Result Image':
          if (this.state.session.details.images && this.state.session.details.images.result) {
            return true;
          }
          return false;
        case 'Juiciness Image':
          if (this.state.session.details.images && this.state.session.details.images.juciness) {
            return true;
          }
          return false;
        case 'Additional Feedback':
          if (
            (this.state.session.details.texture >= -5 && this.state.session.details.texture <= 5) ||
            (this.state.session.details && this.state.session.details.comment !== 'No Comment')
          ) {
            return true;
          }
          return false;
        default:
          return true;
      }
    } else {
      return false;
    }
  };

  render() {
    const availabeSvg = <img className={classes.SvgIcon} src={Available} alt="Available" />;
    const notAvailableSvg = <img className={classes.SvgIcon} src={NotAvailable} alt="Not Available" />;

    const steps = ['Session Details', 'Result Image', 'Juiciness Image', 'Additional Feedback'];
    let nav = 'Overview';
    for (let i = 0; i <= this.state.activeStep; i++) {
      if (i === 2 && !this.state.isJucinessReq) {
        continue;
      }
      nav += ` / ${steps[i]}`;
    }
    let jucinessReq = null;
    if (this.state.session) {
      if (Number.isInteger(+this.state.session.program)) {
        jucinessReq = this.juicinessProductIdx.includes(this.state.session.program);
      } else {
        jucinessReq = this.juicinessProductIds.includes(this.state.session.program.toLowerCase());
      }
    }
    // console.log(this.state);
    return (
      <Layout breadcrumb={nav}>
        <Stepper
          activeStep={this.state.activeStep}
          alternativeLabel
          connector={
            <StepConnector
              classes={{
                root: classes.StepConnector,
                active: classes.ActiveConnector,
                completed: classes.CompletedConnector,
              }}
            />
          }
          classes={{
            root: classes.StepperRoot,
          }}
        >
          {steps.map((label, index) => (
            <Step
              key={label}
              completed={this.checkStepStaus(label)}
              classes={{
                root: classes.StepRoot,
                //active: classes.StepLabelActive,
                completed: classes.StepCompleted,
              }}
              onClick={() => this.jumpStepHandler(index)}
              className={index === 2 && this.state.session && !this.state.isJucinessReq && classes.DisableStep}
            >
              {/* <StepButton
                            onClick={()=>this.jumpStepHandler(index)}
                        > */}
              <StepLabel
                classes={{
                  root: classes.StepperButton,
                  active: classes.StepLabelActive,
                  completed: classes.StepLabelCompleted,
                }}
                StepIconProps={{
                  classes: {
                    root: classes.StepLabelIcon,
                    active: classes.StepIconActive,
                    completed: classes.StepIconCompleted,
                  },
                }}
              >
                <Typography classes={{ root: classes.StepperText }}>{label}</Typography>
              </StepLabel>
              {/* </StepButton> */}
            </Step>
          ))}
        </Stepper>
        {this.state.session ? (
          <Grid container className={classes.SessionsContainer}>
            <Grid container justify="space-between" alignItems="center" className={classes.SessionsLabel}>
              <Grid item>
                <div className={classes.TableTitle}>{steps[this.state.activeStep]}</div>
              </Grid>
            </Grid>
            <TableContainer component={Paper} className={classes.TableContainer}>
              <Table aria-label="customized table">
                <TableHeader
                  titleList={[
                    'Location',
                    'Oven',
                    'Session ID',
                    'Date',
                    'Start Time',
                    'Duration',
                    'Program',
                    'Result Image',
                    'Juiciness Image',
                    'Add Feedback',
                  ]}
                />
                <TableBody>
                  {
                    this.state.session && (
                      // this.state.session.map((session) => (
                      <TableRow key={Math.random(1000)} className={classes.TabelRow}>
                        <TableCell align="center" scope="row">
                          {this.state.session.location}
                        </TableCell>
                        <TableCell className={this.state.session.is_temp ? classes.SelectCell : null} align="center">
                          {!this.state.session.is_temp ? this.state.session.appliance_name : '--'}
                          {this.state.tempSelect && this.sessionsList()}
                        </TableCell>
                        <TableCell className={this.state.session.is_temp ? classes.SelectCell : null} align="center">
                          {!this.state.session.is_temp ? this.state.session._id : '--'}
                        </TableCell>
                        <TableCell className={this.state.session.is_temp ? classes.SelectCell : null} align="center">
                          {!this.state.session.is_temp ? (
                            <Moment format="D.MM.YYYY" withTitle>
                              {new Date(this.state.session.timestamp * 1000)}
                            </Moment>
                          ) : (
                              '--'
                            )}
                        </TableCell>
                        <TableCell className={this.state.session.is_temp ? classes.SelectCell : null} align="center">
                          {!this.state.session.is_temp ? (
                            <Moment format="HH:mm" withTitle>
                              {new Date(this.state.session.timestamp * 1000)}
                            </Moment>
                          ) : (
                              '--'
                            )}
                        </TableCell>
                        <TableCell
                          className={this.state.session.is_temp ? classes.SelectCell : null}
                          style={{ position: 'relative' }}
                          align="center"
                        >
                          {!this.state.session.is_temp
                            ? this.state.session.duration &&
                            this.secondsToHMS(this.state.session.duration.overall - this.state.session.duration.preheating)
                            : '--'}
                          {this.state.session.is_temp && (
                            <img
                              className={classes.SelectArrow}
                              onClick={this.showSelect}
                              src={this.state.showSelect ? ArrowUp : ArrowDown}
                              alt="up/down"
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {this.state.activeStep !== 0 ? (
                            ![-1, 0].includes(this.state.session.program) ? (
                              this.props.programs.find((program) => {
                                return program.name === this.state.session.program || program.index === this.state.session.program;
                              }).name
                            ) : (
                                '--'
                              )
                          ) : (
                              <Autocomplete
                                //disabled={true}
                                id={'program-native-helper'}
                                options={this.props.programs}
                                getOptionLabel={(option) => option.name}
                                value={this.props.programs.find((program) => {
                                  return program.name === this.state.session.program || program.index === this.state.session.program;
                                })}
                                classes={{
                                  root: classes.FilterRoot,
                                  inputRoot: classes.FilterInput,
                                  option: classes.SelectOption,
                                }}
                                onChange={this.programChangeHandler}
                                renderInput={(params) => <TextField {...params} label={'Program'} variant="outlined" />}
                              />
                            )}
                        </TableCell>
                        <TableCell align="center">
                          {this.state.session.details && this.state.session.details.images && this.state.session.details.images.result
                            ? availabeSvg
                            : notAvailableSvg}
                        </TableCell>
                        <TableCell align="center">
                          {jucinessReq
                            ? this.state.session.details && this.state.session.details.images && this.state.session.details.images.juciness
                              ? availabeSvg
                              : notAvailableSvg
                            : 'NA'}
                        </TableCell>
                        <TableCell align="center">
                          {(this.state.session.details &&
                            this.state.session.details.texture >= -5 &&
                            this.state.session.details.texture <= 5) ||
                            (this.state.session.details && this.state.session.details.comment !== 'No Comment')
                            ? availabeSvg
                            : notAvailableSvg}
                        </TableCell>
                      </TableRow>
                    )
                    //))
                  }
                  {/* {this.state.session.is_temp && this.sessionsList()} */}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={classes.StepContentContainer}>
              {/* <div className={classes.StepContent}> */}
              {this.getStepContent()}
              {/* </div> */}
              <Grid className={classes.ActionContainer} container justify="space-between" alignItems="center">
                <Grid item>
                  <Button onClick={this.cancelClickHandler} className={classes.AltBtn}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  {this.state.activeStep === steps.length - 1 && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.session && this.state.session.disregard}
                          onChange={(e) => this.disregardChnageHandler(e.target.checked, 'disregard')}
                          name="disregardSession"
                          color="primary"
                        />
                      }
                      label="Disregard Session"
                    />
                  )}
                  <Button onClick={this.exitClickHandler} className={classes.SecondaryBtn}>
                    Save & Exit
                  </Button>
                  {this.state.activeStep !== steps.length - 1 && (
                    <Fragment>
                      <Button onClick={() => this.nextClickHandler('skip')} className={classes.SecondaryBtn}>
                        Skip & Next
                      </Button>
                      <Button variant="contained" color="primary" onClick={this.nextClickHandler} className={classes.DefaultBtn}>
                        Save & Next
                      </Button>
                    </Fragment>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        ) : (
            <div style={{ textAlign: 'center', fontSize: 50, color: '#9A9A9A', paddingTop: 200, opacity: 0.5 }}>
              {this.state.error ? 'Failed to load data' : 'Loading...'}
            </div>
          )}
        {/* </div> */}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sessions: state.main.sessions,
    session: state.main.session,
    programs: state.main.programs,
    ovens: state.main.ovens,
    //fetchingFlag: state.main.fetchingFlag
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeFetchingFlag: () => dispatch(actions.fetchingFlag()),
    //fetchPrograms: () => dispatch(actions.fetchPrograms())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditSession));
