import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Layout from '../../components/Layout/Layout';
import { Grid } from '@material-ui/core';
import classes from './Reports.module.css';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { TextField, Button, CircularProgress, Backdrop } from '@material-ui/core';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Autocomplete, Pagination } from '@material-ui/lab';
import { Download, ArrowRight, ArrowLeft } from '../../utils/Icons';
import * as actions from '../../store/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { dummyRequests, dummyTraining } from './tempDummy';
import { saveAs } from '@progress/kendo-file-saver';

const Reports = (props) => {
  const [fieldVals, setFieldVals] = useState({});

  const [selLocation, setSelLocation] = useState(null);

  const inputChangeHandler = (val, field) => {
    // setSelLocation(val);
    switch (field) {
      case 'resultsOnTrainig':
        // const dt = new Date(val).getTime();
        if (val && new Date(val).toString() !== 'Invalid Date') {
          const dt = new Date(val);
          const formatedDate = dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2);
          props.getTrainingReport(formatedDate);
        }
        break;
      case 'resultsOnRequest':
        if (fieldVals.location && val && val[0] && val[1]) {
          const dr1 = new Date(val[0]);
          const dr2 = new Date(val[1]);
          const formatedDate1 = dr1.getFullYear() + '-' + ('0' + (dr1.getMonth() + 1)).slice(-2) + '-' + ('0' + dr1.getDate()).slice(-2);
          const formatedDate2 = dr2.getFullYear() + '-' + ('0' + (dr2.getMonth() + 1)).slice(-2) + '-' + ('0' + dr2.getDate()).slice(-2);
          const dr = [formatedDate1, formatedDate2];
          props.getRequestsReport(dr, fieldVals.location.name);
        }
        break;
      case 'donenessOnTrainig':
        // const dt = new Date(val).getTime();
        if (val && new Date(val).toString() !== 'Invalid Date') {
          const dt = new Date(val);
          const formatedDate = dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2);
          props.getDonenessTraining(formatedDate);
        }
        break;
      case 'location':
        if (fieldVals.resultsOnRequest && fieldVals.resultsOnRequest.length && val) {
          const drl1 = new Date(fieldVals.resultsOnRequest[0]);
          const drl2 = new Date(fieldVals.resultsOnRequest[1]);
          const formatedDate1 = drl1.getFullYear() + '-' + ('0' + (drl1.getMonth() + 1)).slice(-2) + '-' + ('0' + drl1.getDate()).slice(-2);
          const formatedDate2 = drl2.getFullYear() + '-' + ('0' + (drl2.getMonth() + 1)).slice(-2) + '-' + ('0' + drl2.getDate()).slice(-2);
          const drl = [formatedDate1, formatedDate2];
          props.getRequestsReport(drl, val.name);
        }
        break;
      case 'donenessOnRequest':
        if (fieldVals.donenessLocation && val && val[0] && val[1]) {
          const dr1 = new Date(val[0]);
          const dr2 = new Date(val[1]);
          const formatedDate1 = dr1.getFullYear() + '-' + ('0' + (dr1.getMonth() + 1)).slice(-2) + '-' + ('0' + dr1.getDate()).slice(-2);
          const formatedDate2 = dr2.getFullYear() + '-' + ('0' + (dr2.getMonth() + 1)).slice(-2) + '-' + ('0' + dr2.getDate()).slice(-2);
          const dr = [formatedDate1, formatedDate2];
          props.getDonenessRequest(dr, fieldVals.donenessLocation.name);
          // props.getDonenessTraining('2020-10-14');
        }
        break;
      case 'donenessLocation':
        if (fieldVals.donenessOnRequest && fieldVals.donenessOnRequest.length && val) {
          const drl1 = new Date(fieldVals.donenessOnRequest[0]);
          const drl2 = new Date(fieldVals.donenessOnRequest[1]);
          const formatedDate1 = drl1.getFullYear() + '-' + ('0' + (drl1.getMonth() + 1)).slice(-2) + '-' + ('0' + drl1.getDate()).slice(-2);
          const formatedDate2 = drl2.getFullYear() + '-' + ('0' + (drl2.getMonth() + 1)).slice(-2) + '-' + ('0' + drl2.getDate()).slice(-2);
          const drl = [formatedDate1, formatedDate2];
          props.getDonenessRequest(drl, val.name);
          // props.getDonenessTraining('2020-10-14');
        }
        break;
    }
    setFieldVals({
      ...fieldVals,
      [field]: val,
    });
  };

  // console.log(fieldVals, 'fieldVals')

  const arrowClickHandler = (direction, field) => {
    if (field === 'resultsOnTrainig') {
      const dt = new Date(fieldVals.resultsOnTrainig);
      const formatedDate = dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2);

      props.getTrainingReport(formatedDate, direction);
    }
    if (field === 'donenessOnTrainig') {
      const dt = new Date(fieldVals.donenessOnTrainig);
      const formatedDate = dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2);
      props.getDonenessTraining(formatedDate, direction);
    }
  };

  useEffect(() => {
    if (
      !props.trainingReport ||
      !Object.keys(props.trainingReport).length ||
      !props.donenessTraining ||
      !Object.keys(props.donenessTraining).length
    ) {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const formatedDate =
        tomorrow.getFullYear() + '-' + ('0' + (tomorrow.getMonth() + 1)).slice(-2) + '-' + ('0' + tomorrow.getDate()).slice(-2);

      props.getTrainingReport(formatedDate, 'lte');
      props.getDonenessTraining(formatedDate, 'lte');
    }

    if (!props.requestReport || !Object.keys(props.requestReport).length) {
      let ourDate1 = new Date();
      let ourDate2 = new Date();
      const pastDate1 = ourDate1.getDate() - 7;
      const pastDate2 = ourDate2.getDate() - 14;
      ourDate1.setDate(pastDate1);
      ourDate2.setDate(pastDate2);
      const dr1 = ourDate2;
      const dr2 = ourDate1;
      const formatedDate1 = dr1.getFullYear() + '-' + ('0' + (dr1.getMonth() + 1)).slice(-2) + '-' + ('0' + dr1.getDate()).slice(-2);
      const formatedDate2 = dr2.getFullYear() + '-' + ('0' + (dr2.getMonth() + 1)).slice(-2) + '-' + ('0' + dr2.getDate()).slice(-2);
      const dr = [formatedDate1, formatedDate2];
      props.getRequestsReport(dr, 'Munich');
      props.getDonenessRequest(dr, 'Munich');
    }
    props.getAvailableEvaluationReports();
    props.getAvailableTrainingReports();
    // props.getAvailableClassificationRequests();
  }, []);

  function compare(a, b) {
    const timeStampA = new Date(a.created_on).getTime();
    const timeStampB = new Date(b.created_on).getTime();
    if (timeStampA < timeStampB) {
      return -1;
    }
    if (timeStampA > timeStampB) {
      return 1;
    }
    return 0;
  }

  const [availableDates, setAvailableDates] = useState(null);
  const [evaluationAvailableDates, setEvaluationAvailableDates] = useState(null);
  const [sortedDates, setSortedDates] = useState(null);
  const [sortedEvaluationAvailableDates, setSortedEvaluationAvailableDates] = useState(null);
  useEffect(() => {
    if (props.availableTrainingReports) {
      let aD = [...props.availableTrainingReports];
      aD = aD.map((d) => {
        const d2 = new Date(d.created_on);
        d2.setHours(0, 0, 0, 0);
        return d2;
      });
      setAvailableDates(aD);
      const sortDates = [...props.availableTrainingReports];
      sortDates.sort(compare);
      setSortedDates(sortDates);
    }
  }, [props.availableTrainingReports]);
  useEffect(() => {
    if (props.availableEvaluationReports) {
      let aD = [...props.availableEvaluationReports];
      aD = aD.map((d) => {
        const d2 = new Date(d.created_on);
        d2.setHours(0, 0, 0, 0);
        return d2;
      });
      setEvaluationAvailableDates(aD);
      const sortDates = [...props.availableEvaluationReports];
      sortDates.sort(compare);
      setSortedEvaluationAvailableDates(sortDates);
    }
  }, [props.availableEvaluationReports]);
  const dateJsx = (field) => {
    let nextDisabled = false;
    let prevDisabled = false;
    let tempSortedDates = field === 'resultsOnTrainig' ? sortedDates && [...sortedDates] : field === 'donenessOnTrainig' ? sortedEvaluationAvailableDates && [...sortedEvaluationAvailableDates] : null;
    if ((field === 'resultsOnTrainig' || field === 'donenessOnTrainig') && tempSortedDates) {
      const minDate = new Date(tempSortedDates[0].created_on).setHours(0, 0, 0, 0);
      const maxDate = new Date(tempSortedDates[tempSortedDates.length - 1].created_on).setHours(0, 0, 0, 0);
      const tempSelDate = new Date(fieldVals[field]);
      tempSelDate.setHours(0, 0, 0, 0);
      
      if (tempSelDate.getTime() === maxDate) {
        nextDisabled = true;
      }
      if (tempSelDate.getTime() === minDate) {
        prevDisabled = true;
      }
    }
    if ((field === 'resultsOnTrainig' || field === 'donenessOnTrainig') && !fieldVals[field]) {
      nextDisabled = true;
      prevDisabled = true;
    }
    // console.log(fieldVals,'fieldVals')
    return (
      <Grid className={classes.FilterGrid}>
        {(field === 'resultsOnTrainig' || field === 'donenessOnTrainig') && (
          <div
            onClick={() => arrowClickHandler('lte', field)}
            style={{ marginRight: 10 }}
            // className={classes.ArrowImgHolder}
            className={`${classes.ArrowImgHolder} ${prevDisabled && classes.ArrowDisabled}`}
          >
            <img src={ArrowLeft} alt="left" className={classes.ArrowImg} />
          </div>
        )}
        <DatePicker
          selected={fieldVals[field] && new Date(fieldVals[field])}
          onChange={(date) => inputChangeHandler(date, field)}
          highlightDates={
            field === 'resultsOnTrainig'
              ? availableDates
                ? availableDates
                : []
              : field === 'donenessOnTrainig'
                ? evaluationAvailableDates
                  ? evaluationAvailableDates
                  : []
                : []
          }
          includeDates={
            field === 'resultsOnTrainig'
              ? availableDates
                ? availableDates
                : []
              : field === 'donenessOnTrainig'
                ? evaluationAvailableDates
                  ? evaluationAvailableDates
                  : []
                : []
          }
          placeholderText="Select Date"
          dateFormat="dd/MM/yyyy"
          className={classes.DatePickerRoot}
        />
        {(field === 'resultsOnTrainig' || field === 'donenessOnTrainig') && (
          <div
            onClick={() => arrowClickHandler('gte', field)}
            style={{ marginLeft: 10 }}
            className={`${classes.ArrowImgHolder} ${nextDisabled && classes.ArrowDisabled}`}
          >
            <img src={ArrowRight} alt="right" className={classes.ArrowImg} />
          </div>
        )}
      </Grid>
    );
  };

  // const [value, onChange] = useState([new Date(), new Date()]);

  const dateRange = (field) => {
    const setRangeDate = (d, pos) => {
      let fullDate = fieldVals[field] ? [...fieldVals[field]] : [];
      if (pos === 'start') {
        fullDate[0] = d;
        fullDate[1] = null;
      } else if (pos === 'end') {
        fullDate[1] = d;
      }
      inputChangeHandler(fullDate, field)
    }
    
    return (
      // <DateRangePicker
      //   onChange={(date) => inputChangeHandler(date, field)}
      //   value={fieldVals[field]}
      //   className={classes.TimeRange}
      //   maxDate={new Date()}
      // // dayPlaceholder='dd'
      // // monthPlaceholder='mm'
      // // yearPlaceholder='yyyy'
      // />
      <div className={classes.DatePickerHolder}>
        <DatePicker
          selected={fieldVals[field] && fieldVals[field][0] ? new Date(fieldVals[field][0]) : ''}
          onChange={(date) => setRangeDate(date, 'start')}
          selectsStart
          startDate={fieldVals[field] && new Date(fieldVals[field][0])}
          endDate={fieldVals[field] && new Date(fieldVals[field][1])}
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
          placeholderText="Select Start"
          className={classes.DatePickerStart}
          highlightDates={availableDates ? availableDates : []}
        />
        <DatePicker
          selected={fieldVals[field] && fieldVals[field][1] ? new Date(fieldVals[field][1]) : ''}
          onChange={(date) => setRangeDate(date, 'end')}
          selectsEnd
          startDate={fieldVals[field] && new Date(fieldVals[field][0])}
          endDate={fieldVals[field] && new Date(fieldVals[field][1])}
          minDate={fieldVals[field] && new Date(fieldVals[field][0])}
          maxDate={new Date()}
          dateFormat="dd/MM/yyyy"
          placeholderText="Select End"
          className={classes.DatePickerEnd}
          highlightDates={availableDates ? availableDates : []}
        />
        {/* <DatePicker
          selected={fieldVals[field] && new Date(fieldVals[field][0])}
          onChange={(date) => inputChangeHandler(date, field)}
          startDate={fieldVals[field] && fieldVals[field][0]}
          endDate={fieldVals[field] && fieldVals[field][1]}
          selectsRange
        // inline
        /> */}
      </div>
    );
  };

  const buttonJsx = (field, disabled = false) => {
    if (!disabled && (!Object.keys(allData[field]).length || !fieldVals[field])) {
      disabled = true;
    }
    if (field === 'resultsOnRequest'
      && (!fieldVals.resultsOnRequest
        || !fieldVals.resultsOnRequest[0]
        || !fieldVals.resultsOnRequest[1]
        || !fieldVals.location)) {
      disabled = true;
    }
    return (
      <Button
        variant="contained"
        disabled={disabled}
        className={classes.Button}
        startIcon={<img src={Download} />}
        alt="download"
        onClick={() => downloadClickHandler(field, 'all')}
        classes={{
          disabled: classes.DisabledButton,
        }}
      >
        Download All
      </Button>
    );
  };

  const allFields = {
    resultsOnTrainig: ['confusion_matrix', 'bar_plot'],
    resultsOnRequest: ['confusion_matrix', 'bar_plot', 'activity_overview'],
    donenessOnTrainig: ['evaluation_report'],
    donenessOnRequest: ['evaluation_report'],
  };

  const allData = {
    resultsOnTrainig: { ...props.trainingReport },
    resultsOnRequest: { ...props.requestReport },
    donenessOnRequest: { ...props.donenessRequest },
    donenessOnTrainig: { ...props.donenessTraining },
  };

  useEffect(() => {
    if (props.trainingReport && props.trainingReport.created_on) {
      setFieldVals({
        ...fieldVals,
        resultsOnTrainig: props.trainingReport.created_on,
      });
    }
  }, [props.trainingReport]);

  useEffect(() => {
    if (props.donenessTraining && props.donenessTraining.created_on) {
      setFieldVals({
        ...fieldVals,
        donenessOnTrainig: props.donenessTraining.created_on,
      });
    }
  }, [props.donenessTraining]);
  
  useEffect(() => {
    // if (props.requestReport && props.requestReport.created_on) {
    // setFieldVals({
    // ...fieldVals,
    // resultsOnRequest: props.requestReport.created_on
    // })
    // }
  }, [props.requestReport]);

  const downloadClickHandler = (field, type) => {
    if (type === 'all') {
      allFields[field].map((k) => {
        downloadSingleFile(allData[field][k], field, k);
      });
    } else {
      downloadSingleFile(allData[field][type], field, type);
    }
  };

  const downloadSingleFile = (file, field, name = 'candyFile') => {
    // 'confusion_matrix', 'bar_plot', 'activity_overview'
    if (name === 'confusion_matrix') {
      name = 'ConfusionMatrix';
    } else if (name === 'bar_plot') {
      name = 'Accuracy';
    } else if (name === 'activity_overview') {
      name = 'ActivityOverview';
    }
    const dt = new Date(allData[field].created_on);
    const formatedDate = dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2);



    let fileName = formatedDate + '_ClassificationOnTraining_' + name + '.png';
    if (field === 'resultsOnRequest') {
      const dt0 = new Date(fieldVals.resultsOnRequest[0]);
      const dt1 = new Date(fieldVals.resultsOnRequest[1]);
      const formatedDate0 = dt0.getFullYear() + '-' + ('0' + (dt0.getMonth() + 1)).slice(-2) + '-' + ('0' + dt0.getDate()).slice(-2);
      const formatedDate1 = dt1.getFullYear() + '-' + ('0' + (dt1.getMonth() + 1)).slice(-2) + '-' + ('0' + dt1.getDate()).slice(-2);

      fileName = formatedDate0 + '_' + formatedDate1 + '_ClassificationOnRequests_' + fieldVals.location.name + '_' + name + '.png';
    }
    if (field === 'donenessOnTrainig') {
      fileName = formatedDate + '_DonenessEstimationOnTraining_Report';
      file = props.donenessTraining.plot;
    }
    if (field === 'donenessOnRequest') {
      const dt2 = new Date(fieldVals.donenessOnRequest[0]);
      const dt3 = new Date(fieldVals.donenessOnRequest[1]);
      const formatedDate2 = dt2.getFullYear() + '-' + ('0' + (dt2.getMonth() + 1)).slice(-2) + '-' + ('0' + dt2.getDate()).slice(-2);
      const formatedDate3 = dt3.getFullYear() + '-' + ('0' + (dt3.getMonth() + 1)).slice(-2) + '-' + ('0' + dt3.getDate()).slice(-2);

      fileName = formatedDate2 + '_' + formatedDate3 + '_' + 'DonenessEstimationOnRequests_' + fieldVals.donenessLocation.name + '_Report';
      file = props.donenessRequest.metric;
    }
    if (file) {
      try {
        saveAs(file, fileName);
      } catch (e) {
        console.log(e);
        alert('Failed to download as data seems to be not perfectly encoded!');
      }
    }
  };

  const locationDefault = useRef(false);

  useEffect(() => {
    if (!locationDefault.current && props.locations && props.locations.length) {
      locationDefault.current = true;
      let ourDate1 = new Date();
      let ourDate2 = new Date();
      const pastDate1 = ourDate1.getDate() - 7;
      const pastDate2 = ourDate2.getDate() - 14;
      ourDate1.setDate(pastDate1);
      ourDate2.setDate(pastDate2);
      const dr1 = ourDate2;
      const dr2 = ourDate1;
      const formatedDate1 = dr1.getFullYear() + '-' + ('0' + (dr1.getMonth() + 1)).slice(-2) + '-' + ('0' + dr1.getDate()).slice(-2);
      const formatedDate2 = dr2.getFullYear() + '-' + ('0' + (dr2.getMonth() + 1)).slice(-2) + '-' + ('0' + dr2.getDate()).slice(-2);
      const dr = [formatedDate1, formatedDate2];
      setFieldVals({
        ...fieldVals,
        location: props.locations.find((el) => el.name === 'Munich'),
        donenessLocation: props.locations.find((el) => el.name === 'Munich'),
        resultsOnRequest: dr,
        donenessOnRequest: dr,
      });
    }
  }, [props.locations]);

  const isLoading = props.loadingRequestReport || props.loadingTrainingReport || props.loadingDonenessTraining || props.loadingDonenessRequest;
  const isClassificationOnRequest = fieldVals.resultsOnRequest
    && fieldVals.resultsOnRequest[0]
    && fieldVals.resultsOnRequest[1]
    && fieldVals.location;
  const isDonenessOnRequest = fieldVals.donenessOnRequest
    && fieldVals.donenessOnRequest[0]
    && fieldVals.donenessOnRequest[1]
    && fieldVals.donenessLocation;
  return (
    <Layout>
      <Grid container className={classes.ReportsContainer}>
        <div className={classes.ListContainer}>
          <Grid container spacing={3} alignItems="center" className={classes.HeadingRow}>
            <Grid item xs={4} sm={4} className={classes.Heading}>
              1. Classification Results On Training :
            </Grid>
            <Grid item xs={6} sm={6}>
              {dateJsx('resultsOnTrainig')}
            </Grid>
            <Grid item xs={2} sm={2}>
              {buttonJsx('resultsOnTrainig')}
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.ListRow}>
            <Grid item xs={4} sm={4} className={classes.ListItem}>
              Overall Accuracy :
            </Grid>
            <Grid item xs={6} sm={8} className={classes.ListItemVal}>
              {props.trainingReport && fieldVals.resultsOnTrainig && props.trainingReport.overall_accuracy}
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.ListRow}>
            <Grid item xs={4} sm={4} className={classes.ListItem}>
              Confusion Matrix :
            </Grid>
            <Grid item xs={6} sm={8} className={classes.ListItemVal}>
              <span
                className={
                  fieldVals.resultsOnTrainig
                    && allData.resultsOnTrainig
                    && allData.resultsOnTrainig.confusion_matrix
                    ? classes.DownloadLink
                    : classes.DisableDownloadLink
                }
                onClick={() => downloadClickHandler('resultsOnTrainig', 'confusion_matrix')}
              >
                Download Confusion Matrix
              </span>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.ListRow}>
            <Grid item xs={4} sm={4} className={classes.ListItem}>
              Bar Plot :
            </Grid>
            <Grid item xs={6} sm={8} className={classes.ListItemVal}>
              <span
                className={
                  fieldVals.resultsOnTrainig
                    && allData.resultsOnTrainig
                    && allData.resultsOnTrainig.bar_plot
                    ? classes.DownloadLink
                    : classes.DisableDownloadLink
                }
                onClick={() => downloadClickHandler('resultsOnTrainig', 'bar_plot')}
              >
                Download Bar Plot
              </span>
            </Grid>
          </Grid>
        </div>
        <div className={classes.ListContainer}>
          <Grid container spacing={3} alignItems="center" className={classes.HeadingRow}>
            <Grid item xs={4} sm={4} className={classes.Heading}>
              2. Classification Results On Requests :
            </Grid>
            <Grid item xs={6} sm={6}>
              <Grid container>
                <Grid item className={classes.LocationFilter}>
                  <Autocomplete
                    //disabled={field === 'program'}
                    id="-native-helper"
                    options={props.locations ? props.locations : []}
                    size="small"
                    getOptionLabel={(option) => (option.name ? option.name : 'Name not available')}
                    onChange={(e, val) => inputChangeHandler(val, 'location')}
                    //style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Location" variant="outlined" />}
                    classes={{
                      root: classes.LocationRoot,
                      inputRoot: classes.LocationInput,
                      option: classes.SelectOption,
                    }}
                    value={fieldVals['location'] ? fieldVals['location'] : null}
                  //getOptionSelected={option => fieldObj.value === option.name}
                  />
                </Grid>
                <Grid item>{dateRange('resultsOnRequest')}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2}>
              {buttonJsx('resultsOnRequest')}
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.ListRow}>
            <Grid item xs={4} sm={4} className={classes.ListItem}>
              Overall Accuracy :
            </Grid>
            <Grid item xs={6} sm={8} className={classes.ListItemVal}>
              {props.requestReport && isClassificationOnRequest && props.requestReport.overall_accuracy}
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.ListRow}>
            <Grid item xs={4} sm={4} className={classes.ListItem}>
              Confusion Matrix :
            </Grid>
            <Grid item xs={6} sm={8} className={classes.ListItemVal}>
              <span
                className={
                  isClassificationOnRequest
                    && allData.resultsOnRequest
                    && allData.resultsOnRequest.confusion_matrix
                    ? classes.DownloadLink
                    : classes.DisableDownloadLink
                }
                onClick={() => downloadClickHandler('resultsOnRequest', 'confusion_matrix')}
              >
                Download Confusion Matrix
              </span>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.ListRow}>
            <Grid item xs={4} sm={4} className={classes.ListItem}>
              Bar Plot :
            </Grid>
            <Grid item xs={6} sm={8} className={classes.ListItemVal}>
              <span
                className={
                  isClassificationOnRequest
                    && allData.resultsOnRequest
                    && allData.resultsOnRequest.bar_plot
                    ? classes.DownloadLink
                    : classes.DisableDownloadLink
                }
                onClick={() => downloadClickHandler('resultsOnRequest', 'bar_plot')}
              >
                Download Bar Plot
              </span>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.ListRow}>
            <Grid item xs={4} sm={4} className={classes.ListItem}>
              Activity Overview :
            </Grid>
            <Grid item xs={6} sm={8} className={classes.ListItemVal}>
              <span
                className={
                  isClassificationOnRequest
                    && allData.resultsOnRequest
                    && allData.resultsOnRequest.activity_overview
                    ? classes.DownloadLink
                    : classes.DisableDownloadLink
                }
                onClick={() => downloadClickHandler('resultsOnRequest', 'activity_overview')}
              >
                Download Activity Overview
              </span>
            </Grid>
          </Grid>
        </div>
        <div className={classes.ListContainer}>
          <Grid container spacing={3}>
            <Grid item xs={4} sm={4} alignItems="center" className={classes.Heading}>
              3. Doneness Estimation On Training :
            </Grid>
            <Grid item xs={6} sm={6}>
              {dateJsx('donenessOnTrainig')}
            </Grid>
            <Grid item xs={2} sm={2}></Grid>
          </Grid>
          <Grid container spacing={3} className={classes.ListRow}>
            <Grid item xs={4} sm={4} className={classes.ListItem}>
              Doneness Report :
            </Grid>
            <Grid item xs={6} sm={8} className={classes.ListItemVal}>
              <span
                className={
                  fieldVals.donenessOnTrainig
                    && allData.donenessOnTrainig
                    && allData.donenessOnTrainig.plot
                    ? classes.DownloadLink
                    : classes.DisableDownloadLink
                }
                onClick={() => downloadClickHandler('donenessOnTrainig', 'all')}
              >
                Download Doneness Report
              </span>
            </Grid>
          </Grid>
        </div>
        <div className={classes.ListContainer}>
          <Grid container spacing={3}>
            <Grid item xs={4} sm={4} alignItems="center" className={classes.Heading}>
              4. Doneness Estimation On Requests :
            </Grid>

            <Grid item xs={6} sm={6}>
              <Grid container>
                <Grid item className={classes.LocationFilter}>
                  <Autocomplete
                    //disabled={field === 'program'}
                    id="-native-helper"
                    options={props.locations ? props.locations : []}
                    size="small"
                    getOptionLabel={(option) => (option.name ? option.name : 'Name not available')}
                    onChange={(e, val) => inputChangeHandler(val, 'donenessLocation')}
                    //style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Location" variant="outlined" />}
                    classes={{
                      root: classes.LocationRoot,
                      inputRoot: classes.LocationInput,
                      option: classes.SelectOption,
                    }}
                    value={fieldVals['donenessLocation'] ? fieldVals['donenessLocation'] : null}
                  //getOptionSelected={option => fieldObj.value === option.name}
                  />
                </Grid>
                <Grid item>{dateRange('donenessOnRequest')}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2}></Grid>
          </Grid>
          <Grid container spacing={3} className={classes.ListRow}>
            <Grid item xs={4} sm={4} className={classes.ListItem}>
              Doneness Report :
            </Grid>
            <Grid item xs={6} sm={8} className={classes.ListItemVal}>
              <span
                className={
                  isDonenessOnRequest
                    && allData.donenessOnTrainig
                    && allData.donenessOnTrainig.plot
                    ? classes.DownloadLink
                    : classes.DisableDownloadLink
                }
                onClick={() => downloadClickHandler('donenessOnRequest', 'all')}
              >
                Download Doneness Report
              </span>
            </Grid>
          </Grid>{' '}
        </div>
      </Grid>
      {
        isLoading && (
          <Backdrop className={classes.Backdrop} open={true}>
            <CircularProgress thickness={7} size={100} style={{ color: '#fff' }} />
          </Backdrop>
        )
        // <CircularProgress />
      }
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    trainingReport: state.main.trainingReport,
    loadingTrainingReport: state.main.loadingTrainingReport,
    loadingRequestReport: state.main.loadingRequestReport,
    locations: state.main.locations,
    requestReport: state.main.requestReport,
    availableTrainingReports: state.main.availableTrainingReports,
    donenessTraining: state.main.donenessTraining,
    loadingDonenessTraining: state.main.loadingDonenessTraining,
    loadingDonenessRequest: state.main.loadingDonenessRequest,
    availableEvaluationReports: state.main.availableEvaluationReports,
    donenessRequest: state.main.donenessRequest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTrainingReport: (dt, direction = 'equal') => dispatch(actions.getTrainingReport(dt, direction)),
    getRequestsReport: (dt, loc) => dispatch(actions.getRequestsReport(dt, loc)),
    getDonenessTraining: (dt, dir = 'equal') => dispatch(actions.getDonenessTraining(dt, dir)),
    getDonenessRequest: (dt, loc) => dispatch(actions.getDonenessRequest(dt, loc)),
    getAvailableTrainingReports: () => dispatch(actions.getAvailableTrainingReports()),
    getAvailableEvaluationReports: () => dispatch(actions.getAvailableEvaluationReports()),
    getAvailableClassificationRequests: () => dispatch(actions.getAvailableClassificationRequests())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
