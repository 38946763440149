import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Grid
} from '@material-ui/core';
import SideImage from '../../assets/SideImage.png';
import classes from './Auth.module.css';
import Login from './Login/Login';
import Logout from './Logout/Logout';
import Logo from '../../assets/Haier_Europe_Logo_Login.png';

const Auth = props => {

    const load = props.location.pathname === '/logout' || props.location.pathname === '/sessionexpired' ? <Logout /> : <Login />;
    return (
        <Grid
            container
            direction="row"
            justify="space-evenly"
            className={classes.AuthContainer}
        >
            <Grid item className={classes.LeftGrid}>
                <div style={{ backgroundImage: SideImage }} className={classes.ImageArea}>
                    <img src={SideImage} alt="SideImage" />
                </div>
            </Grid>
            <Grid item className={classes.RightGrid}>
                <img className={classes.LogoImg} src={Logo} alt="Logo" />
                {load}
            </Grid>
        </Grid>
    );
}

const mapStateToProps =state => {
    return{
        sessionExpired: state.main.sessionExpired
    }
}

export default withRouter(connect(mapStateToProps)(Auth));
