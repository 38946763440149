import * as actionTypes from '../actions/actionTypes';

const initialState = {
    ovens: null,
    sessions: null,
    error: null,
    locations: [],
    programs: [],
    rowsPerPage: 50,
    activePage: 1,
    session: null,
    auth: false,
    auth_token: null,
    loading: false,
    loginStart: false,
    loginError: false,
    sessionExpired: false,
    fetchingFlag: false,
    appliedFileters: {},
    filterVals: {
        location: null,
        oven: null,
        date: null,
        program: null,
        sessionDetails: null
    },
    filters: {
        location: null,
        oven: null,
        date: null,
        program: null,
        sessionDetails: null
    }
    //paginationPage: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BAKING_SESSIONS_SUCCESS:
            return {
                ...state,
                sessions: action.sessions,
                activePage: action.activePage,
                rowsPerPage: action.rowsPerPage,
                loading: false
            };
        case actionTypes.BAKING_SESSIONS_FAILED:
            return {
                ...state,
                sessions: null,
                error: action.err,
                loading: false
            }
        case actionTypes.LOCATION_SUCCESS:
            return {
                ...state,
                locations: action.locations
            }
        case actionTypes.OVEN_SUCCESS:
            return {
                ...state,
                ovens: action.ovens
            }
        case actionTypes.PROGRAM_SUCCESS:
            return {
                ...state,
                programs: action.programs,
            }
        case actionTypes.BAKING_SESSION_SUCCESS:
            return {
                ...state,
                session: action.session,
                error: null,
                loading: false,
                fetchingFlag: false
            }
        // case actionTypes.BAKING_SESSION_FAILED:
        //     return{
        //         ...state,
        //         error: action.err
        //     }
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                auth: true,
                auth_token: action.auth_token,
                loginStart: false,
                loginError: false,
                sessionExpired: false
            }
        case actionTypes.DO_LOGOUT:
            return {
                ...state,
                auth: false,
                auth_token: null,
                sessionExpired: true
            }
        case actionTypes.LOADING_DATA:
            return {
                ...state,
                loading: true
            }
        case actionTypes.LOGIN_START:
            return {
                ...state,
                loginStart: true,
                loginError: false
            }
        case actionTypes.LOGIN_FAILED:
            return {
                ...state,
                loginStart: false,
                loginError: action.err
            }
        case actionTypes.FETCHING_FLAG:
            return {
                ...state,
                fetchingFlag: true,
                loading: true
            }
        case actionTypes.ADD_FILTER_VAL:
            return {
                ...state,
                filterVals: action.data
            }
        case actionTypes.SET_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.data[0]]: action.data[1]
                }
            }
        case actionTypes.APPLIED_FILTERS:
            return {
                ...state,
                appliedFileters: action.appliedFilters
            }
        case actionTypes.LOADED_TRAINING_REPORT:
            return {
                ...state,
                trainingReport: action.data,
                loadingTrainingReport: false,
            }
        case actionTypes.LOADED_REQUEST_REPORT:
            return {
                ...state,
                requestReport: action.data,
                loadingRequestReport: false,
            }
        case actionTypes.LOADED_DATA_EVALUTION:
            return {
                ...state,
                dataEvaluation: action.data,
                loadingEvaluation: false
            }
        case actionTypes.LOADING_DATA_EVALUTION:
            return {
                ...state,
                loadingEvaluation: true
            }
        case actionTypes.LOADING_REPORT:
            return {
                ...state,
                [action.data[0]]: action.data[1]
            }
        case actionTypes.APPLY_DATA_EVALUATION:
            return {
                ...state,
                appliedDataEvaluation: action.data
            }
        case actionTypes.LOADED_AVAILABLE_TRAINING_REPORTS:
            return {
                ...state,
                availableTrainingReports: action.data
            }
        case actionTypes.LOADED_AVAILABLE_EVALUATION_REPORTS:
            return {
                ...state,
                availableEvaluationReports: action.data
            }
        case actionTypes.LOADED_CLASSIFICATION_DOCUMENTS:
            return {
                ...state,
                classificationDocuments: action.data
            }
        case actionTypes.LOADED_DONENESS_TRAINING:
            return {
                ...state,
                donenessTraining: action.data,
                loadingDonenessTraining: false,
            }
        case actionTypes.LOADED_DONENESS_REQUEST:
            return {
                ...state,
                donenessRequest: action.data,
                loadingDonenessRequest: false,
            }
        default:
            return state;
    }
}

export default reducer;