import 'date-fns';
import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import Axios from '../../utils/Axios';
import { withRouter } from 'react-router-dom';
import {
    Grid,
    Table,
    TextField,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Paper,
    NativeSelect,
    Dialog
} from "@material-ui/core";
import {
    Autocomplete,
    Pagination
} from '@material-ui/lab';
import TableHeader from '../../components/TableHeader/TableHeader';
import Layout from '../../components/Layout/Layout';
import NoData from '../../components/NoData/NoData';
import classes from './Overview.module.css';
import * as actions from '../../store/actions';
import {
    Available,
    NotAvailable,
    Edit,
    Delete,
    DeleteColored
} from '../../utils/Icons';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

class Overview extends Component {
    state = {
        fields: {
            location: {
                type: 'select',
                name: 'Location',
                options: [],
                value: null
            },
            oven: {
                type: 'select',
                name: 'Oven',
                options: [],
                value: null
            },
            date: {
                type: 'date',
                name: 'Date',
                value: null
            },
            program: {
                type: 'select',
                name: 'Program',
                options: [],
                value: null
            },
            sessionDetails: {
                type: 'select',
                name: 'Session Details',
                options: [
                    { id: 'detail1', name: 'all', label: 'All' },
                    { id: 'detail2', name: 'complete_session', label: 'Complete Sessions' },
                    { id: 'detail9', name: 'disregard', label: 'Disregarded Sessions' },
                    { id: 'detail8', name: 'missing_baking_session', label: 'Missing Baking Session Details' },
                    { id: 'detail3', name: 'missing_complete_feedback', label: 'Missing Complete Feedback' },
                    { id: 'detail4', name: 'missing_feedback', label: 'Missing Feedback (any feedback missing)' },
                    { id: 'detail6', name: 'missing_juciness', label: 'Missing Juiciness' },
                    { id: 'detail5', name: 'missing_result', label: 'Missing Result' },
                    //{ id: 'detail7', name:'missing_add_feedback', label: 'Missing add. Feedback' },
                ],
                value: null
            }
        },
        filters: {
            location: null,
            oven: null,
            date: null,
            program: null,
            sessionDetails: null
        },
        filteredSessions: null,
        deleteDialog: false,
        deleteSessionId: null,
        filterVals: {
            location: null,
            oven: null,
            date: null,
            program: null,
            sessionDetails: null
        }
    }

    juicinessProductIds = ['salmon fillet', 'sea bass fillet', 'tuna steak', 'roast beef', 'veal roast', 'pork roast', 'whole fish (sea bream type)', 'roasted chicken'];
    juicinessProductIdx = [1, 2, 3, 4, 5, 6, 27, 28]

    static getDerivedStateFromProps(props, state) {
        const updatedOptions = { ...state.fields }
        updatedOptions.location.options = props.locations;
        updatedOptions.program.options = props.programs;

        return {
            ...state,
            fields: updatedOptions
        }
    }

    compare = (a, b) => {
        // Use toUpperCase() to ignore character casing
        const bandA = a.name.toUpperCase();
        const bandB = b.name.toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return comparison;
    }

    componentDidMount() {
        if (this.props.fetchingFlag) {
            this.props.fetchBakingSessions(this.props.activePage, this.props.rowsPerPage, this.props.appliedFileters);
        }

        if (this.props.filterVals && this.state.filterVals) {
            const updatedOptions = { ...this.state.fields };
            if (this.props.filterVals.location) {
                const ovens = this.props.filterVals.location.appliances.map((appliance, index) => {
                    // return ({ id: index, name: appliance })
                    return this.props.ovens.find(oven => oven.appliance_id === appliance)
                })
                updatedOptions.oven.options = ovens.sort(this.compare)
            }
            this.setState({ filterVals: this.props.filterVals })
        }
    }

    componentDidUpdate() {
        if (!this.state.filterVals.location && this.props.locations[0] && this.props.ovens) {
            //this.props.addFilterValue('location', this.props.locations[0]);
            this.props.setFilters('location', this.props.locations[0].name);
            this.props.addAppliedFilters({ location: this.props.locations[0].name });
            const updatedOptions = { ...this.state.fields };
            const ovens = this.props.locations[0] && this.props.locations[0].appliances ? this.props.locations[0].appliances.map((appliance, index) => {
                //return ({ id: index, name: appliance })
                return this.props.ovens.find(oven => oven.appliance_id === appliance);
            }) : [];
            updatedOptions.oven.options = ovens.sort(this.compare)
            this.setState({
                fields: updatedOptions,
                filterVals: {
                    ...this.state.filterVals,
                    location: this.props.locations[0]
                }
            })
        }
    }

    inputChangeHandler = (newval, field) => {
        const updatedOptions = { ...this.state.fields }
        const filterVals = { ...this.state.filterVals };
        if (field === 'location' && newval) {
            const selLocation = this.props.locations.find(loc => loc._id === newval._id);
            const ovens = selLocation.appliances.map((appliance, index) => {
                //return ({ id: index, name: appliance })
                return this.props.ovens.find(oven => oven.appliance_id === appliance)
            })
            updatedOptions.oven.options = ovens.sort(this.compare);
        } else if (field === 'location' && !newval) {
            updatedOptions.oven.options = [];
            //this.props.addFilterValue('oven', null);
            filterVals.oven = null;
        }
        if (field === 'date' && newval) {
            let curDate = new Date();
            newval.setHours(curDate.getHours());
            newval.setMinutes(curDate.getMinutes());
            newval.setSeconds(curDate.getSeconds());
            this.props.setFilters('date', newval);
        } else if (field === 'program' && newval) {
            this.props.setFilters('program', newval.index);
        } else if (field === 'oven' && newval) {
            this.props.setFilters('oven', newval.appliance_id);
        } else if (newval) {
            this.props.setFilters(field, newval.name);
        } else {
            this.props.setFilters(field, null);
        }
        //this.props.addFilterValue(field, newval);
        filterVals[field] = newval;
        if (field === 'location' && !newval) {
            //this.props.addFilterValue('oven', null);
            filterVals[field] = newval;
        }

        this.setState(prevState => ({
            ...prevState,
            fields: updatedOptions,
            filterVals: filterVals
        }));
    }

    applyClickHandler = () => {
        if (this.props.filters) {
            this.props.fetchBakingSessions(1, this.props.rowsPerPage, this.props.filters);
            this.props.addAppliedFilters(this.props.filters);
            this.props.addFilterValue(this.state.filterVals);
        }
    }

    paginationClickHandler = (e, page) => {
        this.props.fetchBakingSessions(page, this.props.rowsPerPage, this.props.appliedFileters);
    }

    rowsPerPageChangeHandler = event => {
        const val = +event.target.value;
        this.props.fetchBakingSessions(this.props.activePage, val, this.props.appliedFileters);
    }

    editClickHandler = id => {
        this.props.history.push('/edit-session/' + id)
    }

    deleteHandler = (id, e) => {
        e.preventDefault();
        this.setState({ deleteDialog: true, deleteSessionId: id });
    }

    disableDialog = () => {
        this.setState({ deleteDialog: false })
    }

    deleteSessionHandler = () => {
        Axios.delete(`bakingsessions/${this.state.deleteSessionId}`)
            .then(res => {
                this.props.fetchBakingSessions(this.props.activePage, this.props.rowsPerPage, this.props.appliedFileters);
            })
        this.setState({ deleteDialog: false })
    }

    secondsToHMS = totalSeconds => {
        //let totalSeconds = 28565;
        let hours = ("0" + Math.floor(totalSeconds / 3600)).slice(-2);
        totalSeconds %= 3600;
        let minutes = ("0" + Math.floor(totalSeconds / 60)).slice(-2);
        let seconds = ("0" + totalSeconds % 60).slice(-2);
        return `${hours}:${minutes}:${seconds}`
    }

    render() {
        //console.log(this.state);
        const availabeSvg = <img className={classes.SvgIcon} src={Available} alt="Available" />
        const notAvailableSvg = <img className={classes.SvgIcon} src={NotAvailable} alt="Not Available" />
        const filteredSessions = this.state.filteredSessions || this.props.sessions;

        const noData = <div style={{ textAlign: 'center', fontSize: 50, color: '#9A9A9A', paddingTop: 200, opacity: 0.5 }}>
            {this.props.loading ? 'Loading...'
                : this.props.error ? <Fragment><div>Failed to Load Data</div><p style={{ fontSize: 16 }}>Please check console for more Information</p></Fragment>
                    : <NoData />}
        </div>;
        let paginationText = null;
        if (this.props.sessions && this.props.activePage && this.props.rowsPerPage) {
            const toCount = this.props.activePage * this.props.rowsPerPage < this.props.sessions.count ? this.props.activePage * this.props.rowsPerPage : this.props.sessions.count;
            paginationText = this.props.activePage * this.props.rowsPerPage - this.props.rowsPerPage + 1 + ' - ' + toCount + ' of ' + this.props.sessions.count;
        }

        return (
            <Layout breadcrumb='Overview'>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.FiltersContainer}
                >
                    <Grid item className={classes.FiltersGrid}>
                        <Grid container>
                            {
                                Object.keys(this.state.fields).map(field => {
                                    const fieldObj = this.state.fields[field];
                                    if (fieldObj.type === 'select') {
                                        return (
                                            <Grid key={field} className={classes.FilterGrid}>
                                                <Autocomplete
                                                    //disabled={field === 'program'}
                                                    id={field + '-native-helper'}
                                                    options={fieldObj.options}
                                                    getOptionLabel={(option) => field !== 'sessionDetails' ? option.name ? option.name : 'Name not available' : option.label}
                                                    onChange={(e, val) => this.inputChangeHandler(val, field)}
                                                    //style={{ width: 300 }}
                                                    renderInput={(params) => <TextField {...params} label={fieldObj.name} variant="outlined" />}
                                                    classes={{
                                                        root: classes.FilterRoot,
                                                        inputRoot: classes.FilterInput,
                                                        option: classes.SelectOption
                                                    }}
                                                    value={this.state.filterVals[field] ? this.state.filterVals[field] : null}
                                                //getOptionSelected={option => fieldObj.value === option.name}
                                                />
                                            </Grid>
                                        )
                                    }
                                    if (fieldObj.type === 'date') {
                                        return (
                                            <Grid key={field} className={classes.FilterGrid}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        variant="inline"
                                                        format="dd/MM/yyyy"
                                                        margin="normal"
                                                        id={field + '-date-helper'}
                                                        label={fieldObj.name}
                                                        value={this.state.filterVals[field] ? this.state.filterVals[field] : null}
                                                        onChange={date => this.inputChangeHandler(date, field)}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        classes={{
                                                            root: classes.DateRoot
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        )
                                    }
                                    return null

                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button disabled={false} className={classes.DefaultBtn} variant="contained" color="primary" onClick={this.applyClickHandler}>Apply</Button>
                    </Grid>
                </Grid>
                {filteredSessions && filteredSessions.results.length > 0 && !this.props.loading ?
                    <Grid container className={classes.SessionsContainer}>
                        <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                            className={classes.SessionsLabel}
                        >
                            <Grid item><div className={classes.TableTitle}>Session Overview</div></Grid>
                            <Grid item>
                                {/* {filteredSessions && Math.ceil(filteredSessions.length / this.state.rowsPerPage) > 1 && */}
                                {filteredSessions &&
                                    <div>
                                        <Grid
                                            container
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <span className={classes.PerPage}>Items Per Page</span>
                                                <NativeSelect
                                                    value={this.props.rowsPerPage}
                                                    onChange={this.rowsPerPageChangeHandler}
                                                    inputProps={{
                                                        name: 'Rows Per Page',
                                                        id: 'rowsPerPage-helper',
                                                    }}
                                                    classes={{
                                                        root: classes.PerPageSelect
                                                    }}
                                                    className={classes.PerPageMain}
                                                >
                                                    {[50, 100, 150, 200, 250, 300, 350, 400, 450, 500].map(count => {
                                                        return <option key={count} value={count}>{count}</option>;
                                                    })}
                                                </NativeSelect>
                                                <span className={classes.PaginationText}>{paginationText && paginationText}</span>
                                            </Grid>
                                            <Grid item>
                                                <Pagination
                                                    count={Math.ceil(filteredSessions.count / this.props.rowsPerPage)}
                                                    variant="outlined"
                                                    shape="rounded"
                                                    onChange={this.paginationClickHandler}
                                                    page={this.props.activePage}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table aria-label="customized table">
                                <TableHeader
                                    titleList={['Location', 'Oven', 'Date', 'Start Time', 'Duration', 'Program', 'Result Image', 'Juiciness Image', 'Add Feedback', '']}
                                />
                                <TableBody>
                                    {/* {filteredSessions &&
                                    filteredSessions.slice(this.state.paginationPage * this.state.rowsPerPage, this.state.paginationPage * this.state.rowsPerPage + this.state.rowsPerPage).map((session) => ( */}
                                    {filteredSessions && filteredSessions.results.map((session, idx) => {
                                        const isResultImage = session.details && session.details.images && session.details.images.result;
                                        const jucinessReq = !Number.isInteger(session.program) && this.juicinessProductIds.includes(session.program.toLowerCase());
                                        const isJucinessImage = session.details && session.details.images && session.details.images.juciness;
                                        // console.log(session.program);
                                        return (
                                            <TableRow className={classes.TableRow} key={Math.random(1000)}>
                                                <TableCell onClick={() => this.editClickHandler(session._id)} align="center" component="th" scope="row">
                                                    {session.location}
                                                </TableCell>
                                                <TableCell onClick={() => this.editClickHandler(session._id)} align="center">{session.is_temp ? '--' : session.appliance_id}</TableCell>
                                                <TableCell onClick={() => this.editClickHandler(session._id)} align="center">{session.is_temp ? '--' : <Moment format="D.MM.YYYY" withTitle>{new Date(session.timestamp * 1000)}</Moment>}</TableCell>
                                                <TableCell onClick={() => this.editClickHandler(session._id)} align="center">{session.is_temp ? '--' : <Moment format="HH:mm" withTitle>{new Date(Math.floor(session.timestamp * 1000))}</Moment>}</TableCell>
                                                <TableCell onClick={() => this.editClickHandler(session._id)} align="center">{session.is_temp ? '--' : (this.secondsToHMS(session.duration.cooking)>0 ? this.secondsToHMS(session.duration.cooking) : this.secondsToHMS(session.duration.overall))  }</TableCell>
                                                <TableCell onClick={() => this.editClickHandler(session._id)} align="center">{session.program && session.program !== -1 ? session.program : '--'}</TableCell>
                                                <TableCell onClick={() => this.editClickHandler(session._id)} align="center">{isResultImage ? availabeSvg : notAvailableSvg}</TableCell>
                                                <TableCell onClick={() => this.editClickHandler(session._id)} align="center">{jucinessReq ? isJucinessImage ? availabeSvg : notAvailableSvg : 'NA'}</TableCell>
                                                <TableCell onClick={() => this.editClickHandler(session._id)} align="center">{session.details && session.details.texture >= -5 && session.details.texture <= 5
                                                    || session.details && session.details.comment !== 'No Comment'
                                                    ? availabeSvg
                                                    : notAvailableSvg}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <button className={classes.ActionButton} onClick={() => this.editClickHandler(session._id)}>
                                                        <img className={classes.SvgIcon} src={Edit} alt="Available" />
                                                    </button>
                                                    <button className={classes.ActionButton} onClick={e => (
                                                        this.deleteHandler(session._id, e)
                                                    )}>
                                                        <img className={classes.SvgIcon} src={Delete} alt="Available" />
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid> :
                    noData
                }
                <Dialog
                    open={this.state.deleteDialog}
                    //onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{
                        paperScrollPaper: classes.DeletPaperScrollPaper
                    }}
                >
                    <div>
                        <img className={classes.DeleteSessionImg} src={DeleteColored} alt="Delete" />
                        <h2>Delete Session Details?</h2>
                        <p>Are you sure you want to delete session details from the recorded quality measurements?</p>
                        <div className={classes.DailogBtnGrp}>
                            <Button onClick={this.disableDialog} className={`${classes.DailogBtn} ${classes.DailogNoBtn}`}>No</Button>
                            <Button onClick={this.deleteSessionHandler} className={`${classes.DailogBtn} ${classes.DailogYesBtn}`} color="primary">Yes</Button>
                        </div>
                    </div>
                </Dialog>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return ({
        sessions: state.main.sessions,
        locations: state.main.locations,
        ovens: state.main.ovens,
        programs: state.main.programs,
        rowsPerPage: state.main.rowsPerPage,
        activePage: state.main.activePage,
        loading: state.main.loading,
        error: state.main.error,
        fetchingFlag: state.main.fetchingFlag,
        filterVals: state.main.filterVals,
        filters: state.main.filters,
        appliedFileters: state.main.appliedFileters
        //paginationPage: state.main.paginationPage
    })
}

const mapDispatchToProps = dispatch => {
    return {
        fetchBakingSessions: (activePage, rowsPerPage, filters) => dispatch(actions.fetchBakingSessions(activePage, rowsPerPage, filters)),
        addFilterValue: filterVals => dispatch(actions.addFilterValue(filterVals)),
        setFilters: (field, val) => dispatch(actions.setFilters(field, val)),
        addAppliedFilters: filters => dispatch(actions.addAppliedFilters(filters))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Overview));
