import * as actionTypes from './actionTypes';
import axios from 'axios';
import { currentApiUrlPrefix } from '../../utils/EnvironmentVariable';


const loginSuccess = data => {
    return{
        type: actionTypes.LOGIN_SUCCESS,
        auth_token: data.auth_token
    }
}

const loginStart = () => {
    return{
        type: actionTypes.LOGIN_START
    }
}

const loginFailed = err => {
    return{
        type: actionTypes.LOGIN_FAILED,
        err: err
    }
}

export const doLogout = () =>{
    localStorage.removeItem('token');
    localStorage.removeItem('expiryTime');
    localStorage.removeItem('rememberMe');
    return{
        type: actionTypes.DO_LOGOUT
    }
}

export const checkAuthTimeout = expiryTime => {
    return dispatch => {
        setTimeout(()=>{
            dispatch(doLogout());
        }, expiryTime )
    };
}

export const doLogin = (email, password, isRememberMe) => {
    return dispatch => {
        dispatch(loginStart());
        axios.post(`${currentApiUrlPrefix}/api/auth/token/login`,{
            username: email,
            password: password
          })
            .then(res => {
                localStorage.setItem('token', res.data.auth_token);
                localStorage.setItem('rememberMe', isRememberMe);
                localStorage.setItem('expiryTime', new Date().setSeconds(3600));
                if(!isRememberMe){
                    dispatch(checkAuthTimeout(3600 * 1000));
                }
                dispatch(loginSuccess(res.data));
            })
            .catch(err => {
                dispatch(loginFailed(err));
            });
    };
}

export const checkAuthStatus = () => {
   
    if(localStorage.getItem('token') && localStorage.getItem('rememberMe')){
        return{
            type: actionTypes.LOGIN_SUCCESS,
            auth_token: localStorage.getItem('token')
        }
    }else if(localStorage.getItem('token') && localStorage.getItem('expiryTime') > new Date()){
        return{
            type: actionTypes.LOGIN_SUCCESS,
            auth_token: localStorage.getItem('token')
        }
    }else{
        return{
            type: actionTypes.DO_LOGOUT
        }
    }
}