const currentEnvironment =
  window.location.hostname &&
  (window.location.hostname === 'localhost'
    ? 'local'
    : 'server');
    
const apiUrlPrefixes = {
    local: 'http://10.1.14.4:9042',
    server: window.location.origin,
};

const currentApiUrlPrefix = apiUrlPrefixes[currentEnvironment];

export { currentApiUrlPrefix }