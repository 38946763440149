import React from 'react';
import classes from './Layout.module.css';

const Layout = props => {
    return(
        <div className={classes.Layout}>
            <div className={classes.Breadcrumb}>{props.breadcrumb}</div>
            {props.children}
        </div>
    );
}

export default Layout;