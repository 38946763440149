import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Button,
    Typography
} from '@material-ui/core';
import classes from './Logout.module.css';

const Logout = props => {
    const loginClickHandler = () =>{
        props.history.replace('/login');
    }

    let heading = 'You have been logged out.';
    let subHeading = `Don't worry you can login below.`;

    if(props.location.pathname === '/sessionexpired'){
        heading = 'Session Expired';
        subHeading = `You are being timed out due to inactivity. You will be redirected to the login page.`;
    }

    return (
        <div>
            <Typography
                variant="h4"
                gutterBottom={false}
                className={classes.LogoutTitle}
                align="left"
            >
                {heading}
            </Typography>
            <Typography variant="h6" className={classes.LogoutSubTitle} align="left">
                {subHeading}
            </Typography>
            <Button
                onClick={loginClickHandler}
                variant="contained"
                className={classes.LoginButton}
            >
                Log In
            </Button>
        </div>
    )
}

export default withRouter(Logout);