import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Layout from '../../components/Layout/Layout';
import classes from './Statistics.module.css';
import { Grid, Button, CircularProgress, Backdrop } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Download, ArrowRight, ArrowLeft } from '../../utils/Icons';
import { BlobProvider, Document, Page, PDFViewer } from '@react-pdf/renderer';
import * as actions from '../../store/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { dummyPdf } from '../Reports/tempDummy';

const Statistics = (props) => {
  const [selDate, setSelDate] = useState(null);

  const dateChangeHandler = (d) => {
    if (d && new Date(d).toString() !== 'Invalid Date') {
      const dt = new Date(d);
      const formatedDate = dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2);
      props.getDataEvalution(formatedDate);
    }
    setSelDate(d);
  };

  let blob = new Blob(['Hello, world!'], { type: 'application/pdf' });
  // var fileURL = URL.createObjectURL(dummyPdf.data_evalution_report);
  //    window.open(fileURL);
  const fileURL = props.dataEvaluation ? true : false;
  let noData = false;

  if (fileURL && !Object.keys(props.dataEvaluation).length) {
    noData = true;
  }

  const downloadSingleFile = () => {
    const dt = new Date(selDate);
    const formatedDate =  dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2);

    const fileName = formatedDate + '_Statistics';
    const link = document.createElement('a');
    link.href = props.dataEvaluation.data_evaluation_report;
    // link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
    link.parentNode.removeChild(link);
  };

  const arrowClickHandler = (direction) => {
    if (selDate) {
      const dt = new Date(selDate);
      const formatedDate = dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2);
      props.getDataEvalution(formatedDate, direction);
    }
  };

  const [loading, setLoading] = useState(false);

  // const applyClickHandler = () => {
  //     // if (selDate) {
  //     //     const dt = new Date(selDate);
  //     //     const formatedDate = dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + (dt.getDate())).slice(-2);

  //     //     props.getDataEvalution(formatedDate)
  //     // }

  //     if (selDate && props.dataEvaluation) {
  //         setLoading(true);
  //         props.applyDataEvalution(props.dataEvaluation)
  //     }
  // }

  useEffect(() => {
    if (props.dataEvaluation && props.dataEvaluation.created_on) {
      setLoading(false);
      setSelDate(props.dataEvaluation.created_on);
    }
  }, [props.dataEvaluation]);

  // useEffect(() => {
  //     if (props.dataEvaluation) {
  //         setLoading(false);
  //     }
  // }, [props.dataEvaluation]);

  useEffect(() => {
    if (!props.dataEvaluation || !Object.keys(props.dataEvaluation).length) {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const formatedDate =
        tomorrow.getFullYear() + '-' + ('0' + (tomorrow.getMonth() + 1)).slice(-2) + '-' + ('0' + tomorrow.getDate()).slice(-2);

      props.getDataEvalution(formatedDate, 'lte');
    }
    props.getAvailableEvaluationReports();
  }, []);

  function compare(a, b) {
    const timeStampA = new Date(a.created_on).getTime();
    const timeStampB = new Date(b.created_on).getTime();
    if (timeStampA < timeStampB) {
      return -1;
    }
    if (timeStampA > timeStampB) {
      return 1;
    }
    return 0;
  }

  const [availableDates, setAvailableDates] = useState(null);
  const [sortedDates, setSortedDates] = useState(null);
  useEffect(() => {
    if (props.availableEvaluationReports) {
      let aD = [...props.availableEvaluationReports];
      aD = aD.map((d) => {
        const d2 = new Date(d.created_on);
        d2.setHours(0, 0, 0, 0);
        return d2;
      });
      setAvailableDates(aD);
      const sortDates = [...props.availableEvaluationReports];
      sortDates.sort(compare);
      setSortedDates(sortDates);
    }
  }, [props.availableEvaluationReports]);

  let nextDisabled = false;
  let prevDisabled = false;
  if (selDate && sortedDates) {
    const minDate = new Date(sortedDates[0].created_on).setHours(0, 0, 0, 0);
    const maxDate = new Date(sortedDates[sortedDates.length - 1].created_on).setHours(0, 0, 0, 0);
    // curDate.setHours(0, 0, 0, 0);
    const tempSelDate = new Date(selDate);
    tempSelDate.setHours(0, 0, 0, 0);
    if (tempSelDate.getTime() === maxDate) {
      nextDisabled = true;
    }
    if (tempSelDate.getTime() === minDate) {
      prevDisabled = true;
    }
  } else if (!selDate) {
    nextDisabled = true;
    prevDisabled = true;
  }

  return (
    <Layout>
      <Grid container justify="space-between" alignItems="center" className={classes.StatisticsContainer}>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <span className={classes.Heading}>Evaluation Metrics Statistics :</span>
            </Grid>
            <Grid item className={classes.FilterGrid}>
              <div
                onClick={() => arrowClickHandler('lte')}
                style={{ marginRight: 10 }}
                className={`${classes.ArrowImgHolder} ${prevDisabled && classes.ArrowDisabled}`}
              >
                <img src={ArrowLeft} alt="left" className={classes.ArrowImg} />
              </div>
              <DatePicker
                selected={selDate && new Date(selDate)}
                onChange={(date) => dateChangeHandler(date)}
                highlightDates={availableDates ? availableDates : []}
                includeDates={availableDates ? availableDates : []}
                placeholderText="Select Date"
                dateFormat="dd/MM/yyyy"
                className={classes.DatePickerRoot}
              />
              <div
                onClick={() => arrowClickHandler('gte')}
                style={{ marginLeft: 10 }}
                className={`${classes.ArrowImgHolder} ${nextDisabled && classes.ArrowDisabled}`}
              >
                <img src={ArrowRight} alt="right" className={classes.ArrowImg} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item>
                    <Button
                        disabled={false}
                        className={classes.DefaultBtn}
                        variant="contained"
                        color="primary"
                        onClick={applyClickHandler}
                        classes={{
                            disabled: classes.DisabledButton
                        }}
                        disabled={props.dataEvaluation ? Object.keys(props.dataEvaluation).length ? false : true : true}
                    >
                        Apply
                    </Button>
                </Grid> */}
      </Grid>
      <Grid container className={classes.PdfContainer}>
        <Grid container justify="space-between" alignItems="center" className={classes.PdfRow}>
          <Grid item>
            {props.dataEvaluation && selDate && Object.keys(props.dataEvaluation).length
              ? ('0' + new Date(props.dataEvaluation.created_on).getDate()).slice(-2) +
                '-' +
                ('0' + (new Date(props.dataEvaluation.created_on).getMonth() + 1)).slice(-2) +
                '-' +
                new Date(props.dataEvaluation.created_on).getFullYear()
              : 'PDF Name'}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={classes.Button}
              startIcon={<img src={Download} alt="download" />}
              onClick={downloadSingleFile}
              classes={{
                disabled: classes.DisabledButton,
              }}
              disabled={
                props.dataEvaluation
                && selDate 
                && props.dataEvaluation.data_evaluation_report
                  ? Object.keys(props.dataEvaluation).length
                    ? false
                    : true
                  : true
              }
            >
              Download
            </Button>
          </Grid>
        </Grid>
        <div className={!fileURL || noData || !selDate ? classes.PdfHolder : classes.PdfShow}>
          {!fileURL || noData || !selDate ? (
            <div className={classes.PdfDiv}>
              <div className={classes.Pdf}>{noData ? 'No Data' : 'PDF'}</div>
            </div>
          ) : // <img src={dummyPdf.data_evalution_report} />
          loading ? (
            <div className={classes.PdfDiv}>
              <div className={classes.Pdf}>Loading...</div>
            </div>
          ) : (
            <iframe src={props.dataEvaluation.data_evaluation_report} style={{ width: '100%', height: '100%' }} frameborder="0"></iframe>
          )}
        </div>
      </Grid>
      {
        props.loadingEvaluation && (
          <Backdrop className={classes.Backdrop} open={true}>
            <CircularProgress thickness={7} size={100} style={{ color: '#fff' }} />
          </Backdrop>
        )
        // <CircularProgress />
      }
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    dataEvaluation: state.main.dataEvaluation,
    loadingEvaluation: state.main.loadingEvaluation,
    availableEvaluationReports: state.main.availableEvaluationReports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDataEvalution: (dt, dir = 'equal') => dispatch(actions.getDataEvalution(dt, dir)),
    getAvailableEvaluationReports: () => dispatch(actions.getAvailableEvaluationReports()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
