import React, { Component, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Overview from './containers/Overview/Overview';
import CandyHeader from './components/CandyHeader/CandyHeader';
import EditSession from './containers/EditSession/EditSession';
import Reports from './containers/Reports/Reports';
import Statistics from './containers/Statistics/Statistics';
import classes from './App.module.css';
import Auth from './components/Authentication/Auth';
import Store from './store/store';
import Axios from './utils/Axios';
import Init from './utils/Init';
import { connect } from 'react-redux';
import * as actions from './store/actions';

class App extends Component {

  componentDidMount(){
    this.props.checkAuthStatus();
    //this.props.checkAuthTimeout(3600);
  }

  render() {

    const token = Store.getState().main.auth_token;
    if(token){
      Axios.defaults.headers['Authorization'] = 'Token ' + token;
    }
    
    //console.log(this.props.auth);
    let routes = (
      <Switch>
        <Route path='/login' component={Auth} />
        <Route path='/logout' component={Auth} />
        <Route path='/sessionexpired' component={Auth} />
        <Route component={Auth} />
        {/* <Route render={() => <Redirect to='/login' />} /> */}
      </Switch>
    )
    
    if (this.props.auth) {
      routes = (
        <Fragment>
          <Init />
          <CandyHeader />
          <Switch>
            <Route path='/' exact component={Overview} />
            <Route path='/reports' exact component={Reports} />
            <Route path='/statistics' exact component={Statistics} />
            <Route path='/edit-session/:id' component={EditSession} />
            <Route render={() => <Redirect to='/' />} />
            {/* <Redirect from='/' to='/overview' /> */}
          </Switch>
        </Fragment>
      )
    }

    return (
      <div className={classes.AppRoot}>
        {routes}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return({
      auth: state.main.auth
  })
}

const mapDispatchToProps = dispatch => {
  return{
      checkAuthStatus : () => dispatch(actions.checkAuthStatus()),
      //checkAuthTimeout: expiryTime => dispatch(actions.checkAuthTimeout(expiryTime))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
