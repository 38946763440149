export const BAKING_SESSIONS_SUCCESS = 'BAKING_SESSIONS_SUCCESS';
export const BAKING_SESSIONS_FAILED = 'BAKING_SESSIONS_FAILED';
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS';
export const OVEN_SUCCESS = 'OVEN_SUCCESS';
export const PROGRAM_SUCCESS = 'PROGRAM_SUCCESS';
export const BAKING_SESSION_SUCCESS = 'BAKING_SESSION_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const DO_LOGOUT = 'DO_LOGOUT';
export const LOADING_DATA = 'LOADING_DATA';
export const CHECK_AUTH = 'CHECK_AUTH';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const FETCHING_FLAG = 'FETCHING_FLAG';
export const ADD_FILTER_VAL = 'ADD_FILTER_VAL';
export const SET_FILTERS = 'SET_FILTERS';
export const APPLIED_FILTERS = 'APPLIED_FILTERS';
export const LOADED_TRAINING_REPORT = 'LOADED_TRAINING_REPORT';
export const LOADED_REQUEST_REPORT = 'LOADED_REQUEST_REPORT';
export const LOADED_DATA_EVALUTION = 'LOADED_DATA_EVALUTION';
export const LOADING_DATA_EVALUTION = 'LOADING_DATA_EVALUTION';
export const LOADING_REPORT = 'LOADING_REPORT';
export const APPLY_DATA_EVALUATION = 'APPLY_DATA_EVALUATION';
export const LOADED_AVAILABLE_TRAINING_REPORTS = 'LOADED_AVAILABLE_TRAINING_REPORTS';
export const LOADED_AVAILABLE_EVALUATION_REPORTS = 'LOADED_AVAILABLE_EVALUATION_REPORTS';
export const LOADED_CLASSIFICATION_DOCUMENTS = 'LOADED_CLASSIFICATION_DOCUMENTS';
export const LOADED_DONENESS_TRAINING = 'LOADED_DONENESS_TRAINING';
export const LOADED_DONENESS_REQUEST = 'LOADED_DONENESS_REQUEST';