import * as actionTypes from './actionTypes';
import Axios from '../../utils/Axios';

const bakingSessionsSuccess = (sessions, activePage, rowsPerPage) => {
    return {
        type: actionTypes.BAKING_SESSIONS_SUCCESS,
        sessions: sessions,
        activePage: activePage,
        rowsPerPage: rowsPerPage
    }
}

const bakingSessionsLoading = () => {
    return {
        type: actionTypes.LOADING_DATA
    }
}

const bakingSessionsfailed = err => {
    return {
        type: actionTypes.BAKING_SESSIONS_FAILED,
        err: err
    }
}

const locationSuccess = locations => {
    return {
        type: actionTypes.LOCATION_SUCCESS,
        locations: locations
    }
}

const ovensSuccess = ovens => {
    return {
        type: actionTypes.OVEN_SUCCESS,
        ovens: ovens
    }
}

const programsSuccess = programs => {
    return {
        type: actionTypes.PROGRAM_SUCCESS,
        programs: programs
    }
}

export const fetchBakingSessions = (activePage, rowsPerPage, filters = null) => {
    let url = `bakingsessions?limit=${rowsPerPage}&offset=${rowsPerPage * (activePage - 1)}`;
    if (filters) {
        Object.keys(filters).forEach(filter => {
            switch (filter) {
                case 'location':
                    if (filters[filter]) {
                        url += `&location=${filters[filter]}`;
                    }
                    break;
                case 'oven':
                    if (filters[filter]) {
                        url += `&appliance=${filters[filter]}`;
                    }
                    break;
                case 'date':
                    if (filters[filter]) {
                        const date = new Date(filters[filter]);
                        const milliseconds = date.getTime();
                        url += `&date=${milliseconds/1000}`;
                    }
                    break;
                case 'program':
                    if (filters[filter]) {
                        url += `&program=${filters[filter]}`;
                    }
                    break;
                case 'sessionDetails':
                    if (filters[filter] && filters[filter] !== 'all') {
                        url += `&${filters[filter]}`
                    }
                    break;
                default:
                    return true;
            }
        })
    }
    
    return dispatch => {
        dispatch(bakingSessionsLoading());
        Axios.get(url)
            .then(res => {
                dispatch(bakingSessionsSuccess(res.data, activePage, rowsPerPage));
            })
            .catch(err => {
                dispatch(bakingSessionsfailed(err));
            });
    };
}

export const fetchLocations = () => {
    return dispatch => {
        Axios.get('locations')
            .then(res => {
                const locations = res.data.results;
                dispatch(locationSuccess(locations));
            })
    };
}

export const fetchOvens = () => {
    return dispatch => {
        Axios.get(`appliance`)
            .then(res => {
                const ovens = res.data;
                dispatch(ovensSuccess(ovens));
            })
    };
}

const compare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.name.toUpperCase();
    const bandB = b.name.toUpperCase();
  
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
}

export const fetchPrograms = () => {
    return dispatch => {
        Axios.get(`programs`)
            .then(res => {
                let programs = res.data;
                programs = programs.sort(compare)
                dispatch(programsSuccess(programs));
            })
    };
}

export const addFilterValue = filterVals =>{
    return {
        type: actionTypes.ADD_FILTER_VAL,
        data: filterVals
    }
}

export const setFilters = (field, val) => {
    return{
        type: actionTypes.SET_FILTERS,
        data: [field, val]
    }
}

export const addAppliedFilters = filters => {
    return{
        type: actionTypes.APPLIED_FILTERS,
        appliedFilters: filters
    }
}

