import * as actionTypes from './actionTypes';
import Axios from '../../utils/Axios';


const loadedDataEvalution = data => {
    return{
        type: actionTypes.LOADED_DATA_EVALUTION,
        data: data
    }
}

const loadingDataEvalution = () => {
    return{
        type: actionTypes.LOADING_DATA_EVALUTION,
    }
}

export const applyDataEvalution = data => {
    return{
        type: actionTypes.APPLY_DATA_EVALUATION,
        data: data
    }
}

export const getDataEvalution = (date,direction) => {
    return dispatch => {
        dispatch(loadingDataEvalution());
        Axios.get(`evaluation-report/` + date + '/' + direction)
            .then(res => {
                dispatch(loadedDataEvalution(res.data));
            })
    };
}

const loadedAvailableEvaluationReports = data => {
    return{
        type: actionTypes.LOADED_AVAILABLE_EVALUATION_REPORTS,
        data: data
    }
}

export const getAvailableEvaluationReports = () => {
    return dispatch => {
        Axios.get(`evaluation-reports`)
            .then(res => {
                dispatch(loadedAvailableEvaluationReports(res.data));
            })
            .catch(err=>{
                // dispatch(loadingReport('loadingRequestReport',false));
            })
    };
}