import * as actionTypes from './actionTypes';
import Axios from '../../utils/Axios';


const loadedTrainingReport = data => {
    return{
        type: actionTypes.LOADED_TRAINING_REPORT,
        data: data
    }
}

const loadingReport = (field,flag) => {
    return{
        type: actionTypes.LOADING_REPORT,
        data: [field,flag]
    }
}


export const getTrainingReport = (date, direaction) => {
    return dispatch => {
        dispatch(loadingReport('loadingTrainingReport',true));
        Axios.get(`training-report/` + date + '/' + direaction)
            .then(res => {
                // console.log(res.data,'res.data')
                dispatch(loadedTrainingReport(res.data));
            })
            .catch(err=>{
                dispatch(loadingReport('loadingTrainingReport',false));
            })
    };
}

const loadedRequestReport = data => {
    return{
        type: actionTypes.LOADED_REQUEST_REPORT,
        data: data
    }
}


export const getRequestsReport = (dates, location) => {
    return dispatch => {
        dispatch(loadingReport('loadingRequestReport',true));
        Axios.get(`get-requests_report/`+ location + '/' + dates[0] + '/' + dates[1])
            .then(res => {
                dispatch(loadedRequestReport(res.data));
            })
            .catch(err=>{
                dispatch(loadingReport('loadingRequestReport',false));
            })
    };
}

const loadedAvailableTrainingReports = data => {
    return{
        type: actionTypes.LOADED_AVAILABLE_TRAINING_REPORTS,
        data: data
    }
}

export const getAvailableTrainingReports = () => {
    return dispatch => {
        Axios.get(`training-reports`)
            .then(res => {
                dispatch(loadedAvailableTrainingReports(res.data));
            })
            .catch(err=>{
                // dispatch(loadingReport('loadingRequestReport',false));
            })
    };
}

const loadedClassificationDocuments = data => {
    return{
        type: actionTypes.LOADED_CLASSIFICATION_DOCUMENTS,
        data: data
    }
}

export const getAvailableClassificationRequests = () => {
    return dispatch => {
        dispatch(loadingReport('loadingRequestReport',true));
        Axios.get(`classification-documents`)
            .then(res => {
                dispatch(loadedClassificationDocuments(res.data));
                dispatch(loadingReport('loadingRequestReport',false));
            })
            .catch(err=>{
                // dispatch(loadingReport('loadingRequestReport',false));
            })
    };
}

const loadedDonenessTraining = data => {
    return{
        type: actionTypes.LOADED_DONENESS_TRAINING,
        data: data
    }
}

export const getDonenessTraining = (date, direaction) => {
    return dispatch => {
        dispatch(loadingReport('loadingDonenessTraining',true));
        Axios.get(`doneness-training-report/` + date + '/' + direaction)
            .then(res => {
                // console.log(res.data,'res.data')
                dispatch(loadedDonenessTraining(res.data));
            })
            .catch(err=>{
                dispatch(loadingReport('loadingDonenessTraining',false));
            })
    };
}

const loadedDonenessRequest = data => {
    return{
        type: actionTypes.LOADED_DONENESS_REQUEST,
        data: data
    }
}

export const getDonenessRequest = (dates, location) => {
    // return dispatch => {
    //     dispatch(loadingReport('loadingDonenessTraining',true));
    //     Axios.get(`doneness-training-report/` + date + '/' + direaction)
    //         .then(res => {
    //             // console.log(res.data,'res.data')
    //             dispatch(loadedDonenessTraining(res.data));
    //         })
    //         .catch(err=>{
    //             dispatch(loadingReport('loadingDonenessTraining',false));
    //         })
    // };
    return dispatch => {
        dispatch(loadingReport('loadingDonenessRequest',true));
        Axios.get(`get-doneness_requests_report/`+ location + '/' + dates[0] + '/' + dates[1])
            .then(res => {
                dispatch(loadedDonenessRequest(res.data));
            })
            .catch(err=>{
                dispatch(loadingReport('loadingRequestReport',false));
            })
    };
}