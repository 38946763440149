import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {
    Grid,
    ListItem,
    List
} from '@material-ui/core';
import Moment from 'react-moment';
import classes from './CandyHeader.module.css';
import Logo from '../../assets/Haier_Europe_Logo_Header.png';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { User } from '../../utils/Icons';

const CandyHeader = props => {
    const [curTime, setCurTime] = useState(new Date());
    const [showMenu, setShowMenu] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurTime(new Date())
        }, 60000);
        return () => clearInterval(interval);

    }, []);

    const logoutClickHandler = () => {
        props.doLogout();
        props.history.replace('/logout');
    }

    const userMenuClickHandler = () => {
        setShowMenu(!showMenu);
    }
    const routeName = props.location.pathname;
    // console.log(props.location.pathname)
    return (
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.Header}
        >
            <Grid item item xs={12} sm={4}>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                >
                    <Grid>
                        <Link to='/' ><img style={{ verticalAlign: "middle" }} src={Logo} alt="Haier Europe" /></Link>
                    </Grid>
                    <Grid item className={classes.MenuHolder}>
                        <div>
                            <span className={`${classes.MenuItemHolder} ${routeName === '/' && classes.Active}`}>
                                <Link className={classes.MenuItem} to='/' >Overview</Link>
                            </span>
                            <span className={`${classes.MenuItemHolder} ${routeName === '/reports' && classes.Active}`}>
                                <Link className={classes.MenuItem} to='/reports' >Reports</Link>
                            </span>
                            <span className={`${classes.MenuItemHolder} ${routeName === '/statistics' && classes.Active}`}>
                                <Link className={classes.MenuItem} to='/statistics' >Statistics</Link>
                            </span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item item xs={12} sm={4} style={{textAlign:'center'}}>
                <div className={classes.DateTime}><Moment format="HH:mm | Do MMMM YYYY" withTitle>{curTime}</Moment></div>
            </Grid>
            <Grid item item xs={12} sm={4} style={{textAlign:'right'}}>
                <img className={classes.User} onClick={userMenuClickHandler} src={User} alt='user' />
                <List
                    className={`${classes.List} ${showMenu && classes.Show}`}
                    component="nav"
                    aria-label="user menu"
                >
                    <ListItem
                        className={classes.ListItem}
                        button
                        onClick={logoutClickHandler}
                    >
                        Logout
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    )
}

// const mapStateToProps = state => {
//     return({
//         rowsPerPage: state.main.rowsPerPage,
//         activePage: state.main.activePage,
//         auth: state.main.auth
//     })
//   }

const mapDispatchToProps = dispatch => {
    return {
        doLogout: () => dispatch(actions.doLogout())
    }
}

export default withRouter(connect(null, mapDispatchToProps)(CandyHeader));