import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import {
    FormControlLabel,
    Checkbox,
    TextField,
    Button,
    Typography
} from '@material-ui/core';
import classes from './Login.module.css';
import {
    ShowPassword,
    HidePassword
} from '../../../utils/Icons';

const Login = props => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isRememberMe, setIsRememberMe] = useState(false);
    const [showPass, setShowPass] = useState(false);

    const logInSubmitHandler = () => {
        props.doLogin(username, password, isRememberMe);
    }

    const usernameChangeHandler = e => {
        setUsername(e.target.value);
    }

    const passwordChangeHandler = e => {
        setPassword(e.target.value);
    }

    const handlePasswordVisiblity = () => {
        setShowPass(!showPass);
    }
    
    return (
        <Fragment>
            <header className={classes.LoginHeader}>
            {/* <Hidden>
          <Logo path={logoPath} className={classes.logo} />
        </Hidden> */}
            {/* <Hidden smDown>
          <Logo lg path={logoPath} />
        </Hidden> */}
            <Typography
                variant="h4"
                gutterBottom={false}
                className={classes.LoginTitle}
                align="left"
            >
                Welcome,
                    </Typography>
            <Typography variant="h6" className={classes.LoginSubTitle} align="left">
                Please login to your account
                    </Typography>
        </header>
            <form className={classes.container} noValidate autoComplete="off">
                <TextField
                    id="standard-username-input"
                    label="Username"
                    InputLabelProps={{ classes: { root: classes.inputLabel } }}
                    className={classes.TextField}
                    //value={username}
                    //onChange={e => setUsername(e.target.value)}
                    type="text"
                    margin="dense"
                    InputProps={{
                        classes: { inputMarginDense: classes.inputfield }
                    }}
                    onChange={usernameChangeHandler}
                //error={isErrorEmail}
                //onKeyPress={keyPress}
                //   helperText={
                //     isErrorEmail ? (
                //       <span className={classes.alignRight}>
                //         {t('validations.emailError')}
                //       </span>
                //     ) : (
                //       ''
                //     )
                //   }
                />
                <TextField
                    id="standard-password-input"
                    label="Password"
                    InputLabelProps={{ classes: { root: classes.inputLabel } }}
                    className={classes.TextField}
                    margin="dense"
                    type={showPass ? 'text' : 'password'}
                    onChange={passwordChangeHandler}
                //type={isVisiblePass ? 'text' : 'password'}
                //value={pass}
                //onChange={e => setPass(e.target.value)}
                  InputProps={{
                    endAdornment: (
                        <img 
                            onClick={handlePasswordVisiblity} 
                            src={ !showPass ? ShowPassword : HidePassword }
                            className={classes.ShowPassword}
                            alt='showPassword' />
                    //   <CustomSVG
                    //     name={isVisiblePass ? 'showPassword' : 'hidePassword'}
                    //     fill={theme.palette.primary.dark}
                    //     onClick={handlePasswordVisiblity}
                    //     className={classes.hidePassword}
                    //   />
                    ),
                    classes: { inputMarginDense: classes.inputfield }
                  }}
                //   onKeyPress={keyPress}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            //defaultChecked={defaultChecked}
                            checked={isRememberMe}
                            onChange={(event) => setIsRememberMe(event.target.checked)}
                            value="rememberMe"
                            color="primary"
                            className={classes.RemembermeCheckbox}
                        />
                    }
                    label="Remember Me"
                    className={classes.RemembermeLabelContainer}
                    classes={{ label: classes.RemembermeLabel }}
                />
                <Button
                    onClick={logInSubmitHandler}
                    variant="contained"
                    className={classes.LoginButton}
                    disabled={ !username || !password }
                >
                    { props.loginStart ? 'Logging in...' : 'Log In' }
        </Button>
            </form>
            { props.loginError ? <div className={classes.Error}>Failed to Log In</div> : null }
        </Fragment>
    );
}

const mapStateToProps = state => {
    return{
        loginStart: state.main.loginStart,
        loginError: state.main.loginError
    }
}

const mapDispatchToProps = dispatch => {
    return{
        doLogin: (username, password, isRememberMe) => dispatch(actions.doLogin(username, password, isRememberMe))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
