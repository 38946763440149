import React from 'react';
import {
    TableCell,
    TableRow,
    TableHead
} from "@material-ui/core";
import classes from './TableHeader.module.css'


const TableHeader = props => {
    return (
        <TableHead>
            <TableRow className={classes.TableHeadings}>
                {
                    props.titleList.map(title=>(
                        <TableCell key={`${title}-${Math.random(1000)}`} align="center">{title}</TableCell>
                    ))
                }
            </TableRow>
        </TableHead>
    )
}

export default TableHeader;