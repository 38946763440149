/**
 * @file Manages the configuration settings for the redux store and web socket.
 * @namespace store
 */

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import mainReducer from './reducer/main';

const rootReducer = combineReducers({
    main: mainReducer
});

/**
 * Redux store.
 * @name store
 * @constant
 * @memberof store
 */
// const store = createStore(
//   rootReducer,
//   {},
//   composeWithDevTools(
//     applyMiddleware(
//       thunk
//     )
//   )
// );

const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(thunk)));

export default store;
