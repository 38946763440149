import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Slide } from 'react-slideshow-image';
import { mergeClasses } from '@material-ui/styles';
import classes from './ImageSlider.module.css'
import Axios from '../../utils/Axios';
import { Button, Dialog, CircularProgress, Grid } from "@material-ui/core";
import {
    Available,
    NotAvailable,
    First,
    Last,
    Pause,
    Play,
    Next,
    Previous,
    SkipHand,
} from '../../utils/Icons';
import { currentApiUrlPrefix } from '../../utils/EnvironmentVariable';

const ImageSlider = props => {
    const slideRef = useRef();
    const [curIndex, setCurIndex] = useState(1);
    const [properties, setProperties] = useState({
        duration: 3000,
        transitionDuration: 500,
        infinite: true,
        arrows: false,
        autoplay: false,
        // pauseOnHover: true,
        onChange: (oldIndex, newIndex) => {
            setCurIndex(newIndex + 1);
        }
    });

    const [openDownload, setOpenDownload] = useState(false);
    const [singleDownload, setSingleDownload] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [autoPlay, setAutoPlay] = useState(false);

    const handleSingleImageClick = () => {
        // event.preventDefault();
        setSingleDownload(true);
    }

    // useEffect(() => {
    //     document.addEventListener('contextmenu', handleContextMenu);
    //     return () => {
    //         document.removeEventListener('contextmenu', handleContextMenu);
    //     }
    // }, []);

    const onDownloadClickHandler = () => {
        setSuccess(false);
        setFailure(false);
        setOpenDownload(true);
        Axios.get(`bakingsessions/${props.bId}/measurements/compressed`, { responseType: 'blob' })
            .then(res => {
                setSuccess(true);
                setTimeout(function () { setOpenDownload(false); }, 2000);
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${props.bId}.zip`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(err => {
                setFailure(true);
                setTimeout(function () { setOpenDownload(false); }, 2000);
            })
    }

    let srcPre = currentApiUrlPrefix.split(/[/?#]/);
    srcPre = srcPre[0] + '//' + srcPre[2]

    const slides = (
        props.slideImages.map((image, index) => (
            <div onClick={handleSingleImageClick} key={'image-' + index} className={mergeClasses.Slide}>
                {/* <img style={{height:'100%', width:'100%'}} src={srcPre + image.image} /> */}
                <div className={classes.ImageDiv} style={{ 'backgroundImage': `url(${srcPre + image.image})` }}>
                    <span></span>
                </div>
            </div>
        ))
    )

    const availabeSvg = <img className={classes.SvgIcon} height={60} width={60} src={Available} alt="Available" />;
    const notAvailableSvg = <img className={classes.SvgIcon} height={60} width={60} src={NotAvailable} alt="Not Available" />;
    let renderArray = [<CircularProgress size={60} thickness={5} />, 'Processing Your Download!', 'Please wait for some time'];

    if (success) {
        renderArray = [availabeSvg, 'Downloading Succeded!', 'Please check your downloads.'];
    } else if (failure) {
        renderArray = [notAvailableSvg, 'Downloading Failed!', 'Please try again.'];
    }

    const goToImage = action => {
        switch (action) {
            case 'first':
                slideRef.current.goTo(parseInt(0, 10));
                break;
            case 'last':
                slideRef.current.goTo(parseInt(props.slideImages.length - 1, 10));
                break;
            case 'prev':
                slideRef.current.goBack();
                break;
            case 'next':
                slideRef.current.goNext();
                break;
            case 'pause':
                setAutoPlay(false);
                setProperties({
                    ...properties,
                    autoplay: false,
                })
                break;
            case 'play':
                setAutoPlay(true);
                setProperties({
                    ...properties,
                    autoplay: true,
                })
                break;
        }

    }

    const disableDialog = () => {
        setSingleDownload(false);
    }

    const downloadSingleImage = () => {
        setSingleDownload(false);
        // console.log(props.slideImages[curIndex].image)
        const link = document.createElement('a');
        link.href = srcPre + props.slideImages[curIndex - 1].image;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    return (
        <Fragment>
            <div className={classes.SlideContainer}>
                <img className={classes.SkipHand} height={32} width={32} src={SkipHand} alt="SkipHand" />
                <span className={classes.ImageCount}>{curIndex} of {props.slideImages.length}</span>
                <Slide ref={slideRef} {...properties} className={mergeClasses.Slides}>
                    {slides}
                </Slide>
            </div>
            <div className={classes.Player}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item><img onClick={() => goToImage('first')} className={classes.SvgIcon} height={24} width={21} src={First} alt="First" /></Grid>
                    <Grid item><img onClick={() => goToImage('prev')} className={classes.SvgIcon} height={24} width={21} src={Previous} alt="Previous" /></Grid>
                    <Grid item><img onClick={() => goToImage(autoPlay ? 'pause' : 'play')} className={classes.SvgIcon} height={24} width={24} src={autoPlay ? Pause : Play} alt="Pause/Play" /></Grid>
                    <Grid item><img onClick={() => goToImage('next')} className={classes.SvgIcon} height={24} width={21} src={Next} alt="Next" /></Grid>
                    <Grid item><img onClick={() => goToImage('last')} className={classes.SvgIcon} height={24} width={21} src={Last} alt="Last" /></Grid>
                    {/* <Grid item><img className={classes.SvgIcon} height={60} width={60} src={Play} alt="Play" /></Grid> */}
                    <Grid item><Button className={classes.Button} color="primary" onClick={onDownloadClickHandler}>Download All</Button></Grid>
                </Grid>
            </div>
            <Dialog
                open={openDownload}
                //onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paperScrollPaper: classes.DownloadPaperScrollPaper
                }}
            >
                <div>
                    {renderArray[0]}
                    <h2>{renderArray[1]}</h2>
                    <p>{renderArray[2]}</p>
                </div>
            </Dialog>
            <Dialog
                open={singleDownload}
                //onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paperScrollPaper: classes.DownloadPaperScrollPaper
                }}
            >
                <div>
                    {/* <CircularProgress size={60} thickness={5} /> */}
                    <h2>Are you sure you want to download this image?</h2>
                    {/* <p>{renderArray[2]}</p> */}
                    <Button onClick={disableDialog} className={`${classes.DailogBtn} ${classes.DailogNoBtn}`}>No</Button>
                    <Button onClick={downloadSingleImage} className={`${classes.DailogBtn} ${classes.DailogYesBtn}`} color="primary">Yes</Button>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default ImageSlider;