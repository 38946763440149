import * as actionTypes from './actionTypes';
import Axios from '../../utils/Axios';


const bakingSessionSuccess = session => {
    return{
        type: actionTypes.BAKING_SESSION_SUCCESS,
        session: session
    }
}


export const fetchBakingSession = id => {
    return dispatch => {
        Axios.get(`bakingsessions/` + id)
            .then(res => {
                dispatch(bakingSessionSuccess(res.data));
            })
    };
}

export const fetchingFlag = () => {
    return{
        type: actionTypes.FETCHING_FLAG
    }
}