import { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions';

class Init extends Component{
    componentDidMount() {
        //this.props.fetchBakingSessions(this.props.activePage, this.props.rowsPerPage);
        this.props.fetchLocations();
        this.props.fetchPrograms();
        this.props.fetchOvens();
        //this.props.fetchPrograms();
    }
    render(){
        return true;
    }
}

const mapStateToProps = state => {
    return({
        rowsPerPage: state.main.rowsPerPage,
        activePage: state.main.activePage,
        auth: state.main.auth
    })
  }
  
  const mapDispatchToProps = dispatch => {
    return{
        fetchBakingSessions : (activePage, rowsPerPage) => dispatch(actions.fetchBakingSessions(activePage, rowsPerPage)),
        fetchLocations: () => dispatch(actions.fetchLocations()),
        fetchOvens: () => dispatch(actions.fetchOvens()),
        fetchPrograms: () => dispatch(actions.fetchPrograms())
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Init);